export const policysState = {
  terms: {
    value: 1,
    title: "register.terms.userTerms",
    policysVerison: policysVerison("terms"),
    policyTitle: "terms",
  },
  clarificationOfTerms: {
    value: 2,
    title: "register.terms.explicitConsent",
    policysVerison: policysVerison("clarificationOfTerms"),
    policyTitle: "clarificationOfTerms",
  },
  paymentTerms: {
    value: 3,
    title: "register.terms.paymentTerms",
    policysVerison: policysVerison("paymentTerms"),
    policyTitle: "paymentTerms",
  },
  messageAprovalTerms: {
    value: 5,
    title: "register.terms.messageConfirmation",
    policysVerison: policysVerison("messageAprovalTerms"),
    policyTitle: "messageAprovalTerms",
  },
  depositApplicationPrivacyStatement: {
    value: 6,
    title: "register.terms.depositInformation",
    policysVerison: policysVerison("depositApplicationPrivacyStatement"),
    policyTitle: "depositApplicationPrivacyStatement",
  },
  paymentApplicationPrivacyStatement: {
    value: 7,
    title: "register.terms.paymentInformation",
    policysVerison: policysVerison("paymentApplicationPrivacyStatement"),
    policyTitle: "paymentApplicationPrivacyStatement",
  },
};

export function policysVerison(value: any) {
  switch (value) {
    case "terms":
      return 1;
    case "clarificationOfTerms":
      return 1;
    case "paymentTerms":
      return 2;
    case "messageAprovalTerms":
      return 2;
    case "depositApplicationPrivacyStatement":
      return 2;
    case "paymentApplicationPrivacyStatement":
      return 2;
    default:
      return null;
  }
}
