import { createTheme } from "@mui/material";
import themeJson from "../../src/figma/myjsonfile.json";
import unitJson from "../../src/figma/units.json"

declare module "@mui/material/styles" {
  interface Theme {
    custom?: any;
    customDesktop?: any;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom?: any;
    customDesktop?: any;
  }
}

export const theme = createTheme({
  custom: themeJson,
  customDesktop: unitJson 
});
