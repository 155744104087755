import {
  Box,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { tss } from "tss-react/mui";
import { useTheme } from "@emotion/react";
import SidebarLogo from "../../../icons/sidebarLogo.svg";
import SidebarFooterLogo from "../../../icons/sidebarFooter.svg";
import HomeIcon from "../../../icons/sidebarHome.svg";
import GainIcon from "../../../icons/sidebarGains.svg";
import WalletIcon from "../../../icons/sidebarWallet.svg";
import DepositRefundIcon from "../../../icons/sidebarDepositRefund.svg";
import OpportunitiesIcon from "../../../icons/sidebarOpportunities.svg";
import CouponsIcon from "../../../icons/sidebarCoupons.svg";
import ChevronDownIcon from "../../../icons/sidebarChevronDown.svg";
import ChevronUpIcon from "../../../icons/sidebarChevronUp.svg";
import HelpIcon from "../../../icons/sidebarHelp.svg";
import ExitIcon from "../../../icons/sidebarExit.svg";
import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import LoginHeader from "../../LoginHeader/LoginHeader";
import { logout } from "../../../services/loginServices";

type Props = {
  children?: any;
};
const useStyles = tss
  .withParams<{
    customTheme: any;
  }>()
  .create(({ customTheme }) => ({
    headerLogo: {
      display: "flex",
      alignItems: "flex-start",
      padding: customTheme.custom.spacing.lg.value,
    },
    footerContainer: {
      width: "100%",
      bottom: 0,
      borderTop: `1px solid ${customTheme.custom.color.outline.default.value}`,
      ".MuiList-root": {
        padding: `${customTheme.custom.spacing.md.value} ${customTheme.custom.spacing.xs.value} !important`,
      },
      marginTop: "auto",
    },
    footerLogo: {
      padding: customTheme.custom.spacing.lg.value,
      borderTop: `1px solid ${customTheme.custom.color.outline.default.value}`,
    },
    listContainer: {
      ".MuiList-root": {
        padding: `0px ${customTheme.custom.spacing.xs.value}`,
        ".MuiListItemButton-root": {
          padding: `${customTheme.custom.spacing.sm.value} ${customTheme.custom.spacing.xs.value} ${customTheme.custom.spacing.sm.value} ${customTheme.custom.spacing.md.value}`,
          borderRadius: customTheme.custom.radius.xl.value,
          "&:hover": {
            borderRadius: customTheme.custom.radius.xl.value,
            backgroundColor:
              customTheme.custom.color.surface["brand-light"].value,
          },
        },
        ".MuiListItemIcon-root": {
          minWidth: "unset",
          marginRight: customTheme.custom.spacing.sm.value,
        },
        ".MuiListItemText-root": {
          span: {
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            lineHeight: "150%",
            color: customTheme.custom.color.text.primary.value,
          },
        },
      },
    },
    secondIcon: {
      marginRight: "0px !important",
    },
    exitButton: {
      span: {
        color: `${customTheme.custom.color.text.secondary.value} !important`,
      },
    },
    rightIcon: {
      display: "block !important",
      div: {
        "&:hover": {
          borderRadius: customTheme.custom.radius.xl.value,
          backgroundColor: `${customTheme.custom.color.surface.default.value} !important`,
        },
      },
      ".MuiListItem-root": {
        paddingLeft: 36,
      },
    },
    expandedList: {
      div: {
        "&:hover": {
          borderRadius: customTheme.custom.radius.xl.value,
          backgroundColor: `${customTheme.custom.color.surface["brand-light"].value} !important`,
        },
      },
    },
  }));

function Sidebar(props: Props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const handleChevronClick = () => {
    setOpen(!open);
  };

  const menu = [
    { label: t("sidebar.home"), icon: HomeIcon, _handleClick: () => {} },
    { label: t("sidebar.gain"), icon: GainIcon, _handleClick: () => {} },
    {
      label: t("sidebar.walletTransaction"),
      icon: WalletIcon,
      rightIcon: open ? ChevronUpIcon : ChevronDownIcon,
      _handleClick: handleChevronClick,
    },
    {
      label: t("sidebar.depositRefundCenter"),
      icon: DepositRefundIcon,
      _handleClick: () => {},
    },
    {
      label: t("sidebar.opportunities"),
      icon: OpportunitiesIcon,
      _handleClick: () => {},
    },
    { label: t("sidebar.coupons"), icon: CouponsIcon, _handleClick: () => {} },
  ];

  const menuBottom = [
    { label: t("sidebar.help"), icon: HelpIcon, _handleClick: () => {} },
    {
      label: t("sidebar.exit"),
      icon: ExitIcon,
      _handleClick: async () => {
        const result = await logout().then((response) => {
          return response;
        });
        if (result?.status === 200) {
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("access_token");
          localStorage.removeItem("wallet_token");
          navigate("/");
        }
      },
      isExitButton: true,
    },
  ];

  const walletTransactions = [
    { label: t("sidebar.currentStatus"), _handleClick: () => {} },
    {
      label: t("sidebar.moneyTransfer"),
      _handleClick: () => {
        navigate("/paragonder");
      },
    },
    { label: t("sidebar.loadBalance"), _handleClick: () => {} },
    { label: t("sidebar.virtualCard"), _handleClick: () => {} },
    {
      label: t("sidebar.accountActivities"),
      _handleClick: () => {
        navigate("/account/transactions");
      },
    },
  ];

  const { classes } = useStyles({
    customTheme: theme,
  });
  const drawerWidth = 272;

  return (
    <>
      <LoginHeader />

      <Box sx={{ display: "flex" }}>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              borderRight: "none",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Grid className={classes.headerLogo}>
            <img src={SidebarLogo} alt="Sidebar Icon" />
          </Grid>
          <Grid className={classes.listContainer}>
            <List>
              {menu.map((element, index) => (
                <ListItem
                  className={element.rightIcon ? classes.rightIcon : ""}
                  key={element.label}
                  disablePadding
                >
                  <ListItemButton onClick={element._handleClick}>
                    <ListItemIcon>
                      <img src={element.icon} />
                    </ListItemIcon>
                    <ListItemText primary={element.label} />
                    {element.rightIcon && (
                      <ListItemIcon className={classes.secondIcon}>
                        <img src={element.rightIcon} />
                      </ListItemIcon>
                    )}
                  </ListItemButton>
                  {element.rightIcon &&
                    open &&
                    walletTransactions.map((element, index) => (
                      <ListItem
                        className={classes.expandedList}
                        key={element.label}
                        disablePadding
                      >
                        <ListItemButton onClick={element._handleClick}>
                          <ListItemText primary={element.label} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                </ListItem>
              ))}
            </List>
          </Grid>

          <Grid className={classes.footerContainer}>
            <Grid className={classes.listContainer}>
              <List>
                {menuBottom.map((element, index) => (
                  <ListItem key={element.label} disablePadding>
                    <ListItemButton onClick={element._handleClick}>
                      <ListItemIcon>
                        <img src={element.icon} />
                      </ListItemIcon>
                      <ListItemText
                        className={
                          element.isExitButton ? classes.exitButton : ""
                        }
                        primary={element.label}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid className={classes.footerLogo}>
              <img src={SidebarFooterLogo} alt="Sidebar Footer" />
            </Grid>
          </Grid>
        </Drawer>
        <Outlet />
      </Box>
    </>
  );
}

export default Sidebar;
