import React, { useEffect, useState } from "react";
import Radius from "../../../icons/radius.svg";
import Check from "../../../icons/check.svg";
import { useRadioGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Fees() {
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [level, setLevel] = useState("");
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    var lang = searchParams?.get("lang") ? searchParams?.get("lang") : "tr";
    i18n.changeLanguage(!!lang ? lang : "tr");
    var level = searchParams?.get("level") ? searchParams?.get("level") : "";
    setLevel(!!level ? level : "");
  }, []);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={styles.tabBox}>
          <Tabs
            centered
            TabIndicatorProps={{
              style: {
                backgroundColor: "#2D9D60",
              },
            }}
            indicatorColor="secondary"
            sx={{
              color: "red",
              fontFamily: "Poppins",
              textColor: "red",
              ".Mui-selected": {
                color: "#2D9D60",
                fontFamily: "Poppins",
              },
            }}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label={
                <span
                  style={{
                    color: value === 0 ? "#2D9D60" : "black",
                    fontWeight: "500",
                    fontSize: 16,
                    textTransform: "none",
                    fontFamily: "Poppins",
                  }}
                >
                  {t("fees.unverified")}
                </span>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <span
                  style={{
                    color: value === 1 ? "#2D9D60" : "black",
                    fontWeight: "500",
                    fontSize: 16,
                    textTransform: "none",
                    fontFamily: "Poppins",
                  }}
                >
                  {t("fees.verified")}
                </span>
              }
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <div style={styles.root}>
            <div style={styles.main}>
              <div style={styles.container}>
                <div style={styles.containerHeader}>
                  {t("fees.depositRefundProcess")}
                </div>
                <div style={styles.IconDescContainer}>
                  <img style={styles.iconImage} src={Check}></img>
                  <img src={Radius}></img>
                  <div style={styles.checkText}>{t("fees.yes")}</div>
                </div>
                <div style={styles.line}></div>
                <div style={styles.containerHeaderLast}>
                  {t("fees.balanceLimit")}
                </div>
                <div style={styles.descContainer}>
                  <div style={styles.descText}>
                    {level == "2" ? "100.000 TL" : "2.750 TL"}
                  </div>
                </div>
                <div style={styles.line}></div>
                <div style={styles.containerHeaderLast}>
                  {t("fees.spendingLimits")}
                </div>
                <div style={styles.descContainerFlex}>
                  <div style={{ flex: 1 }}>
                    <div style={styles.descText}>
                      {level == "2" ? "100.000 TL" : "2.750 TL"}
                    </div>
                    <div style={styles.descTextGray}>{t("fees.monthly")}</div>
                  </div>
                  {level == "2" && <div style={styles.lineHeight}></div>}
                  {level == "2" && (
                    <div style={{ flex: 1 }}>
                      <div style={styles.descText}>{"500.000 TL"}</div>
                      <div style={styles.descTextGray}>{t("fees.yearly")}</div>
                    </div>
                  )}
                </div>
                <div style={styles.line}></div>

                <div style={styles.containerHeaderLast}>
                  <div style={{ flex: 1 }}>{t("fees.withdrawMoney")} </div>
                  <div style={styles.rightIcon}>{t("fees.atm")}</div>
                </div>
                <div style={styles.descContainerFlex}>
                  <div style={{ flex: 1 }}>
                    <div style={styles.descText}>
                      {" "}
                      {level == "2" ? "100.000 TL" : "1.000 TL"}
                    </div>
                    <div style={styles.descTextGray}>{t("fees.yearly")}</div>
                  </div>
                  {level == "2" && <div style={styles.lineHeight}></div>}
                  {level == "2" && (
                    <div style={{ flex: 1 }}>
                      <div style={styles.descText}>500.000 TL</div>
                      <div style={styles.descTextGray}>{t("fees.yearly")}</div>
                    </div>
                  )}
                </div>
                <div style={styles.line}></div>

                <div style={styles.containerHeaderLast}>
                  {t("fees.moneyTransfer")}
                </div>
                <div style={styles.descContainer}>
                  <div style={styles.descText}>
                    {level == "2" ? "100.000 TL" : "2.750 TL"}
                  </div>
                  <div style={styles.descTextGray}>
                    {" "}
                    {level == "2" ? "Yıllık" : <>{t("fees.onlyAccount")}</>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <div style={styles.root}>
            <div style={styles.main}>
              <div style={styles.container}>
                <div style={styles.containerHeader}>
                  {t("fees.depositRefundProcess")}
                </div>
                <div style={styles.IconDescContainer}>
                  <img style={styles.iconImage} src={Check}></img>
                  <img src={Radius}></img>
                  <div style={styles.checkText}>{t("fees.yes")}</div>
                </div>
                <div style={styles.line}></div>
                <div style={styles.containerHeaderLast}>
                  {t("fees.balanceLimit")}
                </div>
                <div style={styles.descContainer}>
                  <div style={styles.descText}>{"100.000 TL"}</div>
                </div>
                <div style={styles.line}></div>
                <div style={styles.containerHeaderLast}>
                  {t("fees.spendingLimits")}
                </div>
                <div style={styles.descContainerFlex}>
                  <div style={{ flex: 1 }}>
                    <div style={styles.descText}>{"100.000 TL"}</div>
                    <div style={styles.descTextGray}>{t("fees.monthly")}</div>
                  </div>
                  <div style={styles.lineHeight}></div>

                  <div style={{ flex: 1 }}>
                    <div style={styles.descText}>{"500.000 TL"}</div>
                    <div style={styles.descTextGray}>{t("fees.yearly")}</div>
                  </div>
                </div>
                <div style={styles.line}></div>

                <div style={styles.containerHeaderLast}>
                  <div style={{ flex: 1 }}>{t("fees.withdrawMoney")} </div>
                  <div style={styles.rightIcon}>{t("fees.atm")}</div>
                </div>
                <div style={styles.descContainerFlex}>
                  <div style={{ flex: 1 }}>
                    <div style={styles.descText}> {"100.000 TL"}</div>
                    <div style={styles.descTextGray}>{t("fees.monthly")}</div>
                  </div>
                  <div style={styles.lineHeight}></div>

                  <div style={{ flex: 1 }}>
                    <div style={styles.descText}>500.000 TL</div>
                    <div style={styles.descTextGray}>{t("fees.yearly")}</div>
                  </div>
                </div>
                <div style={styles.line}></div>

                <div style={styles.containerHeaderLast}>
                  {t("fees.moneyTransfer")}
                </div>
                <div style={styles.descContainer}>
                  <div style={styles.descText}>{"100.000 TL"}</div>
                  <div style={styles.descTextGray}>{t("fees.yearly")}</div>
                </div>
              </div>
            </div>
          </div>
        </CustomTabPanel>
      </Box>
    </>
  );
}

const styles = {
  root: {
    backgroundColor: "#F4F4F4",
    height: "100vh",
  },
  main: {
    backgroundColor: "#F4F4F4",
    display: "flex",

    fontFamily: "Poppins",
  },
  tabBox: { borderBottom: 1, borderColor: "divider", backgroundColor: "white" },

  container: {
    padding: 18,
    backgroundColor: "white",
    borderRadius: "8px",
    width: "100%",
    textAlign: "left" as "left",
  },
  containerHeader: {
    fontWeight: "500",
    fontSize: 16,
  },
  containerHeaderLast: {
    marginTop: 14,
    fontWeight: "500",
    display: "flex",
    fontSize: 16,
    justifyItems: "center",
  },
  iconImage: { position: "absolute" as "absolute", top: 7.4, left: 5.8 },
  descContainer: {
    position: "relative" as "relative",
    //display: "flex",
    justifyItems: "center",
    alignItems: "center",
    marginTop: 3,
    marginBottom: 8,
  },
  descContainerFlex: {
    position: "relative" as "relative",
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    marginTop: 3,
    marginBottom: 8,
  },
  IconDescContainer: {
    position: "relative" as "relative",
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    marginTop: 8,
    marginBottom: 8,
  },
  checkText: {
    color: "#2D9D60",
    marginLeft: 5,
    fontWeight: "500",
    fontSize: 16,
  },
  descText: {
    color: "#1F1F1F",

    fontWeight: "400",
    fontSize: 16,
  },
  descTextGray: {
    color: "#666666",

    fontWeight: "400",
    fontSize: 14,
  },
  line: {
    backgroundColor: "#F4F4F4",
    height: 2,
    width: "100%",
    marginTop: 15,
  },
  lineHeight: {
    backgroundColor: "#F4F4F4",
    height: 40,
    width: 2,
    marginRight: 19,
  },
  rightIcon: {
    color: "#666666",
    fontSize: 12,
    fontWeight: "600",
    backgroundColor: "#F5F5F5",
    borderRadius: 13,
    padding: 3,
  },
};
