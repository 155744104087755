export const LOWER_CASE_LETTERS = /[a-z]/g;

export const UPPER_CASE_LETTERS = /[A-Z]/g;

export const NUMBER_CASE = /^[0-9]*$/;

export const SYMBOL_CASE = /[#?!@$%^&*-_.=/]/g;

export const TURKISH_MOBILE_PHONE =
  /(5)[0-9][0-9]([0-9]){3}([0-9]){2}([0-9]){2}/g;

export const EMAIL_CASE = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const TEXT_ONLY = /^[a-zA-ZğüşöçİĞÜŞÖÇı ]*$/;

export const EMAIL_ONYL = /^[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}/;
