import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export default function FAQ() {
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    var lang = searchParams?.get("lang") ? searchParams?.get("lang") : "tr";
    i18n.changeLanguage(!!lang ? lang : "tr");
  }, []);

  return (
    <div style={styles.root}>
      <div style={styles.main}>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={styles.acordionSummary}
          >
            {t("faq.question1.title")}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <div style={styles.accordionDetails}>
              <p>{t("faq.question1.desc0")}</p>
              <div></div>
              <div>{t("faq.question1.desc1")}</div>
              <div>{t("faq.question1.desc2")}</div>
              <div>{t("faq.question1.desc3")}</div>
              <div>{t("faq.question1.desc4")}</div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0} sx={{ border: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={styles.acordionSummary}
          >
            {t("faq.question2.title")}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <div style={styles.accordionDetails}>
              <div>{t("faq.question2.desc0")}</div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0} sx={{ border: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={styles.acordionSummary}
          >
            {t("faq.question3.title")}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <div style={styles.accordionDetails}>
              <div>{t("faq.question3.desc0")}</div>
              <div>{t("faq.question3.desc1")}</div>
              <div>{t("faq.question3.desc2")}</div>
              <div>{t("faq.question3.desc3")}</div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={0} sx={{ border: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={styles.acordionSummary}
          >
            {t("faq.question4.title")}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <div style={styles.accordionDetails}>
              <div>{t("faq.question4.desc0")}</div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={0} sx={{ border: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={styles.acordionSummary}
          >
            {t("faq.question5.title")}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <div style={styles.accordionDetails}>
              <p>{t("faq.question5.desc0")}</p>
              <div>{t("faq.question5.desc1")}</div>
              <div>{t("faq.question5.desc2")}</div>
              <div>{t("faq.question5.desc3")}</div>
              <div>{t("faq.question5.desc4")}</div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={0} sx={{ border: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={styles.acordionSummary}
          >
            {t("faq.question6.title")}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <div style={styles.accordionDetails}>
              <p>{t("faq.question6.desc0")}</p>
              <div>{t("faq.question6.desc1")}</div>
              <div>{t("faq.question6.desc2")}</div>
              <div>{t("faq.question6.desc3")}</div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={0} sx={{ border: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={styles.acordionSummary}
          >
            {t("faq.question17.title")}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <div style={styles.accordionDetails}>
              <p>{t("faq.question17.desc0")}</p>
              <div>{t("faq.question17.desc1")}</div>
              <div>{t("faq.question17.desc2")}</div>
              <div>{t("faq.question17.desc3")}</div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={0} sx={{ border: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={styles.acordionSummary}
          >
            {t("faq.question19.title")}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <div style={styles.accordionDetails}>
              <p>{t("faq.question19.desc0")}</p>
              <div>{t("faq.question19.desc1")}</div>
              <div>{t("faq.question19.desc2")}</div>
              <div>{t("faq.question19.desc3")}</div>
              <div>{t("faq.question19.desc4")}</div>
              <div>{t("faq.question19.desc5")}</div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={0} sx={{ border: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={styles.acordionSummary}
          >
            {t("faq.question18.title")}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <div style={styles.accordionDetails}>
              <p>{t("faq.question18.desc0")}</p>
              <div>{t("faq.question18.desc1")}</div>
              <div>{t("faq.question18.desc2")}</div>
              <div>{t("faq.question18.desc3")}</div>
              <div>{t("faq.question18.desc4")}</div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={0} sx={{ border: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={styles.acordionSummary}
          >
            {t("faq.question7.title")}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <div style={styles.accordionDetails}>
              <p>{t("faq.question7.desc0")}</p>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={0} sx={{ border: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={styles.acordionSummary}
          >
            {t("faq.question8.title")}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <div style={styles.accordionDetails}>
              <p>{t("faq.question8.desc0")}</p>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={0} sx={{ border: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={styles.acordionSummary}
          >
            {t("faq.question9.title")}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <div style={styles.accordionDetails}>
              <p>{t("faq.question9.desc0")}</p>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={0} sx={{ border: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={styles.acordionSummary}
          >
            {t("faq.question10.title")}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <div style={styles.accordionDetails}>
              <p>{t("faq.question10.desc0")}</p>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={0} sx={{ border: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={styles.acordionSummary}
          >
            {t("faq.question11.title")}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <div style={styles.accordionDetails}>
              <p>{t("faq.question11.desc0")}</p>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={0} sx={{ border: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={styles.acordionSummary}
          >
            {t("faq.question12.title")}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <div style={styles.accordionDetails}>
              <p>{t("faq.question12.desc0")}</p>
              <div>{t("faq.question12.desc1")}</div>
              <div>{t("faq.question12.desc2")}</div>
              <div>{t("faq.question12.desc3")}</div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={0} sx={{ border: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={styles.acordionSummary}
          >
            {t("faq.question13.title")}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <div style={styles.accordionDetails}>
              <p>{t("faq.question13.desc0")}</p>
              <div>{t("faq.question13.desc1")}</div>
              <div>{t("faq.question13.desc2")}</div>
              <div>{t("faq.question13.desc3")}</div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={0} sx={{ border: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={styles.acordionSummary}
          >
            {t("faq.question14.title")}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <div style={styles.accordionDetails}>
              <p>{t("faq.question14.desc0")}</p>
              <div>{t("faq.question14.desc1")}</div>
              <div>{t("faq.question14.desc2")}</div>
              <div>{t("faq.question14.desc3")}</div>
              <div>{t("faq.question14.desc4")}</div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={0} sx={{ border: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={styles.acordionSummary}
          >
            {t("faq.question15.title")}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <div style={styles.accordionDetails}>
              <p>{t("faq.question15.desc0")}</p>
              <div>{t("faq.question15.desc1")}</div>
              <div>{t("faq.question15.desc2")}</div>
              <div>{t("faq.question15.desc3")}</div>
              <div>{t("faq.question15.desc4")}</div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0} sx={{ border: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={styles.acordionSummary}
          >
            {t("faq.question16.title")}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <div style={styles.accordionDetails}>
              <p>{t("faq.question16.desc0")}</p>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

const styles = {
  root: {
    backgroundColor: "white",
    height: "100vh",
  },
  main: {
    padding: 22,
  },
  acordionSummary: {
    textAlign: "left" as "left",
    fontSize: 16,
    fontWeight: "500",
    borderWidth: 0,
    fontFamily: "Poppins",
    padding: 0,
  },
  accordionDetails: {
    textAlign: "left" as "left",
    fontSize: 16,
    fontWeight: 400,
    fontFamily: "Poppins",
    padding: 0,
  },
};
