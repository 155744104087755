import { useTheme } from "@emotion/react";
import { Box, DialogProps, Grid, InputAdornment } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { tss } from "tss-react/mui";
import Button from "../../components/Button/Button";
import CheckBox from "../../components/Checkbox/Checkbox";
import DatePicker from "../../components/DatePicker/DatePicker";
import Layout from "../../components/Layout/Layout";
import ScrollModal from "../../components/Modal/ScollModal/ScrollModal";
import TextField from "../../components/TextField/TextField";
import Tooltip from "../../components/Tooltip/Tooltip";
import { EMAIL_CASE, NUMBER_CASE, TEXT_ONLY } from "../../constants/regex";
import QuestionsIcon from "../../icons/question.svg";
import { errorTckn } from "./function";
import { policysState } from "./policys";

type Props = {
  onClickRegister?: (body: object) => {};
  msisdn?: string;
  registrationToken?: string;
  error: any;
};

const useStyles = tss
  .withParams<{
    error: boolean | undefined;
    checkBoxText: string | undefined;
    customTheme: any;
  }>()
  .create(({ theme, error, checkBoxText, customTheme }) => ({
    containerCenter: {
      width: 400,
    },
    containerTitle: {
      textAlign: "center",
      color: customTheme.custom.color.text.primary.value,
      fontSize: 24,
      fontFamily: "Poppins",
      fontWeight: "500",
      wordWrap: "break-word",
      paddingLeft: 32,
      paddingRight: 32,
    },
    containerDescription: {
      textAlign: "center",
      color: customTheme.custom.color.text.secondary.value,
      fontSize: 16,
      fontFamily: "Poppins",
      fontWeight: "400",
      wordWrap: "break-word",
      marginTop: 6,
    },
    texfieldName: {},
    texfieldSurname: { marginLeft: 16 },
    texfield: { marginBottom: 16 },
    nameSurnameContainer: {
      display: "flex",
      marginTop: 24,
      marginBottom: 16,
    },
    texfieldMail: { marginBottom: 24 },
    checKvkk: {
      flex: 1,
      width: "100%",
      marginTop: "1px",
      marginBottom: "1px",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      color: customTheme.custom.color.surface.black.value,
    },
    questionIcon: { marginLeft: 42 },
    checKvkkUnderline: {
      textDecoration: "underline",
      cursor: "pointer",
      color: customTheme.custom.color.surface.black.value,
    },
    info: {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "18px",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      textAlign: "left",
      marginBottom: 4,
      span: {
        display: "flex",
        alignItems: "center",
        textAlign: "left",
      },
      color: checkBoxText,
    },
    infoLeft: {
      fontFamily: "Poppins",
      fontSize: "14px",
      color: "black",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "18px",
      display: "flex",
      textAlign: "left",
      marginBottom: 12,
      span: {
        display: "flex",
        alignItems: "center",
        textAlign: "left",
      },
      marginLeft: 36,
    },
    registerButton: {
      marginTop: 24,
    },
  }));

export default function Register(props: Props) {
  const theme = useTheme();
  //state
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
  const [policys, setpolicys] = useState<any>(null);
  const [policysVersion, setpolicysVersion] = useState<any>(null);
  const [policysTitle, setpolicysTitle] = useState<any>(null);
  const [modalTitle, setmodalTitle] = useState<any>(null);
  const [agreements, setAgreements] = useState<any>([
    {
      id: "1",
      type: policysState.terms.value.toString(),
      version: policysState.terms.policysVerison?.toString(),
      accepted: false,
    },
    {
      id: "2",
      type: policysState.clarificationOfTerms.value.toString(),
      version: policysState.clarificationOfTerms.policysVerison?.toString(),
      accepted: false,
    },
    {
      id: "3",
      type: policysState.paymentTerms.value?.toString(),
      version: policysState.paymentTerms.policysVerison?.toString(),
      accepted: false,
    },
    {
      id: "5",
      type: policysState.messageAprovalTerms.value,
      version: policysState.messageAprovalTerms.policysVerison?.toString(),
      accepted: false,
    },
    {
      id: "6",
      type: policysState.depositApplicationPrivacyStatement.value.toString(),
      version:
        policysState.depositApplicationPrivacyStatement.policysVerison?.toString(),
      accepted: false,
    },
    {
      id: "7",
      type: policysState.paymentApplicationPrivacyStatement.value.toString(),
      version:
        policysState.paymentApplicationPrivacyStatement.policysVerison?.toString(),
      accepted: false,
    },
  ]);
  const { t } = useTranslation();
  const [name, setName] = useState({ value: "", error: false, errorText: "" });
  const [surname, setSurname] = useState({
    value: "",
    error: false,
    errorText: "",
  });
  const [birthDay, setBirthDay] = useState({
    value: dayjs(""),
    error: false,
    errorText: "",
  });
  const [email, setEmail] = useState({
    value: "",
    error: false,
    errorText: "",
  });
  const [identityNumber, setIdentityNumber] = useState({
    value: "",
    error: false,
    errorText: "",
  });
  const [msisdn, setMsisdn] = useState("");
  const [registrationToken, setRegistrationToken] = useState("");
  const [chechkBoxColor, setchechkBoxColor] = useState("#0F3B6B");

  const [state, setState] = useState({
    terms: { value: false, error: false },
    clarificationOfTerms: { value: false, error: false },
    paymentTerms: { value: false, error: false },
    messageAprovalTerms: { value: false, error: false },
    depositApplicationPrivacyStatement: { value: false, error: true },
    paymentApplicationPrivacyStatement: { value: false, error: true },
  });
  const {
    terms,
    clarificationOfTerms,
    paymentTerms,
    messageAprovalTerms,
    depositApplicationPrivacyStatement,
    paymentApplicationPrivacyStatement,
  } = state;
  //funcitons

  const handleChange = (
    event: any,
    scrollType: DialogProps["scroll"],
    policys?: any,
    policysVersion?: any,
    modalTitle?: any,
    policyTitle?: any
  ) => {
    if (!event.target.checked) {
      setState((prevState) => ({
        ...prevState,
        [policyTitle]: { value: false, error: false },
      }));
      if (policyTitle === "terms") {
        setState((prevState) => ({
          ...prevState,
          clarificationOfTerms: { value: false, error: false },
        }));
      }
    } else {
      setScroll(scrollType);
      setpolicys(policys);
      setpolicysVersion(policysVersion);
      setmodalTitle(modalTitle);
      setpolicysTitle(policyTitle);
      setOpen(true);
    }
  };

  const handleClickOpen =
    (
      scrollType: DialogProps["scroll"],
      policys?: any,
      policysVersion?: any,
      modalTitle?: any,
      policyTitle?: any
    ) =>
    () => {
      setScroll(scrollType);
      setpolicys(policys);
      setpolicysVersion(policysVersion);
      setmodalTitle(modalTitle);
      setpolicysTitle(policyTitle);
      setOpen(true);
    };

  const handleClose = () => {
    setOpen(false);
  };

  async function handleRegister() {
    let birthDayValue;
    if (birthDay.value !== null) {
      const month =
        birthDay.value.get("month") + 1 < 10
          ? `0${birthDay.value.get("month") + 1}`
          : birthDay.value.get("month") + 1;
      const date =
        birthDay.value.get("date") < 10
          ? `0${birthDay.value.get("date")}`
          : birthDay.value.get("date");

      birthDayValue = `${birthDay.value.get("year")}-${month}-${date}`;
    } else {
      birthDayValue = "NaN-NaN-NaN";
    }

    const resultTCKN = errorTckn(identityNumber.value);
    //Check space value
    if (
      identityNumber.value.length === 0 ||
      name.value.length === 0 ||
      surname.value.length === 0 ||
      birthDayValue === "NaN-NaN-NaN" ||
      identityNumber.value.length < 11 ||
      resultTCKN
    ) {
      if (
        identityNumber.value.length === 0 ||
        resultTCKN ||
        identityNumber.value.length < 11
      ) {
        setIdentityNumber((identityNumber) => ({
          ...identityNumber,
          errorText: t("register.error.identityNumber"),
        }));
      }
      if (name.value.length === 0) {
        setName((name) => ({
          ...name,
          errorText: t("register.error.name"),
        }));
      }
      if (surname.value.length === 0) {
        setSurname((surname) => ({
          ...surname,
          errorText: t("register.error.surname"),
        }));
      }
      const date1 = birthDay.value;
      const date2 = dayjs();
      let hours = date2.diff(date1, "hours");
      const days = Math.floor(hours / 24);
      hours = hours - days * 24;
      var totalHours = days * 24 + hours;
      var yearsOK = totalHours > 157784.63 ? true : false;
      if (birthDayValue === "NaN-NaN-NaN") {
        setBirthDay((birtday) => ({
          ...birtday,
          errorText: t("register.error.birtday"),
        }));
      } else if (!yearsOK) {
        setBirthDay((birtday) => ({
          ...birtday,
          errorText: t("register.error.youngBirtday"),
        }));
      }
    }

    setIdentityNumber((identityNumber) => ({
      ...identityNumber,
      error: resultTCKN,
    }));

    const errorName = name.value.length === 0 || name.value.length === 1;
    setName((name) => ({
      ...name,
      error: errorName,
    }));

    const errorSurname = surname.value.length === 0;
    setSurname((surname) => ({
      ...surname,
      error: errorSurname,
    }));

    const emailCheck =
      email.value.length === 0 || !email.value.toLowerCase().match(EMAIL_CASE);
    setEmail((email) => ({
      ...email,
      error: emailCheck,
    }));

    const date1 = birthDay.value && birthDay.value;
    const date2 = dayjs();
    let hours = date2.diff(date1, "hours");
    const days = Math.floor(hours / 24);
    hours = hours - days * 24;
    totalHours = days * 24 + hours;
    yearsOK = totalHours > 157784.63 ? true : false;

    const birtdayCheck =
      (birthDay.value && Number.isNaN(birthDay.value.get("year"))) ||
      !yearsOK ||
      (birthDay.value && birthDay.value.get("year") < 1800);

    setBirthDay((birtday) => ({
      ...birtday,
      error: birtdayCheck,
    }));

    setState((prevState) => ({
      ...prevState,
      terms: { value: state.terms.value, error: !state.terms.value },
      clarificationOfTerms: {
        value: state.clarificationOfTerms.value,
        error: !state.clarificationOfTerms.value,
      },
      paymentTerms: {
        value: state.paymentTerms.value,
        error: !state.paymentTerms.value,
      },
    }));

    if (
      !errorName &&
      !errorSurname &&
      !birtdayCheck &&
      !resultTCKN &&
      state.clarificationOfTerms.value &&
      state.terms.value &&
      state.paymentTerms.value
    ) {
      const body = {
        name: name.value.toLocaleUpperCase("tr-TR"),
        surname: surname.value.toLocaleUpperCase("tr-TR"),
        trIdNumber: identityNumber.value,
        dateOfBirth: birthDayValue,
        email: email.value ? email.value : null,
        msisdn: msisdn,
        registrationToken: registrationToken,
        agreements: agreements,
      };
      props.onClickRegister && props.onClickRegister(body);
    }
  }

  useEffect(() => {
    if (props.msisdn && props.registrationToken) {
      setMsisdn(props.msisdn);
      setRegistrationToken(props.registrationToken);
    }
  }, [props]);

  useEffect(() => {
    if (props.error?.messageId === "SVC100015") {
      setName((name) => ({
        ...name,
        error: true,
        errorText: t("register.error.check"),
      }));
      setIdentityNumber((identityNumber) => ({
        ...identityNumber,
        error: true,
        errorText: t("register.error.check"),
      }));
      setSurname((surname) => ({
        ...surname,
        error: true,
        errorText: t("register.error.check"),
      }));
      setBirthDay((birday) => ({
        ...birday,
        error: true,
        errorText: t("register.error.check"),
      }));
    }
  }, [props.error, t]);

  function modalHandleOkey(policysTitle: any) {
    Object.values(policysState).forEach((element) => {
      if (element.policyTitle == policysTitle) {
        var newAgreements = agreements;

        newAgreements.forEach((agrements: any) => {
          if (agrements.type === element.value) {
            agrements.accepted = true;
          }
        });

        setAgreements(newAgreements);
        setState((prevState) => ({
          ...prevState,
          [policysTitle]: { value: true, error: false },
        }));
      }
    });
    handleClose();
    if (policysTitle == "terms") {
      setTimeout(() => {
        setScroll("paper");
        setpolicys(policysState.clarificationOfTerms.value);
        setpolicysVersion(policysState.clarificationOfTerms.policysVerison);
        setmodalTitle(policysState.clarificationOfTerms.title);
        setpolicysTitle(policysState.clarificationOfTerms.policyTitle);
        setOpen(true);
      }, 200);
    }
  }

  const { classes } = useStyles({
    error: false,
    checkBoxText: chechkBoxColor,
    customTheme: theme,
  });

  return (
    <Layout>
      <Box className={classes.containerCenter}>
        <Grid className={classes.containerTitle}>{t("register.header")}</Grid>
        <Grid className={classes.containerDescription}>
          {t("register.desc")}
        </Grid>
        <Grid className={classes.nameSurnameContainer}>
          <Grid>
            <TextField
              type="text"
              className={classes.texfieldName}
              header={t("register.name")}
              id="outlined-basic"
              onChange={(e) =>
                TEXT_ONLY.test(e.target.value) &&
                setName({
                  ...name,
                  value: e.target.value,
                  error: false,
                })
              }
              inputProsValue={{ maxLength: 30 }}
              value={name.value}
              error={name.error}
              helperText={name.error ? name.errorText : ""}
            />
          </Grid>
          <Grid className={classes.texfieldSurname}>
            <TextField
              header={t("register.surname")}
              onChange={(e) =>
                TEXT_ONLY.test(e.target.value) &&
                setSurname({
                  ...surname,
                  value: e.target.value,
                  error: false,
                })
              }
              inputProsValue={{ maxLength: 30 }}
              value={surname.value}
              error={surname.error}
              helperText={surname.error ? surname.errorText : ""}
            />
          </Grid>
        </Grid>
        <TextField
          className={classes.texfield}
          header={t("register.identityNumber")}
          onChange={(e) =>
            NUMBER_CASE.test(e.target.value) &&
            setIdentityNumber({
              ...identityNumber,
              value: e.target.value,
              error: false,
            })
          }
          inputProsValue={{ maxLength: 11 }}
          value={identityNumber.value}
          error={identityNumber.error}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end" className={classes.questionIcon}>
                <Tooltip
                  placement={"top"}
                  title={t("register.info.identityNumber")}
                >
                  <img src={QuestionsIcon} alt="question-icon" />
                </Tooltip>
              </InputAdornment>
            ),
          }}
          helperText={identityNumber.error ? identityNumber.errorText : ""}
        />

        <Grid className={classes.texfield} item xs={12}>
          <DatePicker
            header={t("register.birtday")}
            value={birthDay.value}
            onChange={(newValue: any) =>
              setBirthDay({
                ...birthDay,
                value: newValue,
                error: false,
              })
            }
            error={birthDay.error}
            helperText={birthDay.error ? birthDay.errorText : ""}
          />
        </Grid>
        <TextField
          className={classes.texfieldMail}
          header={t("register.email")}
          onChange={(e) =>
            setEmail({
              ...email,
              value: e.target.value,
            })
          }
          placeHolder={t("register.optinal")}
          value={email.value}
          //error={email.error}
        />
        <CheckBox
          checked={terms.value && clarificationOfTerms.value}
          error={terms.error || clarificationOfTerms.error}
          helperText={t("register.error.required")}
          onChange={(e) =>
            handleChange(
              e,
              "paper",
              policysState.terms.value,
              policysState.terms.policysVerison,
              policysState.terms.title,
              policysState.terms.policyTitle
            )
          }
          name="approveTermofUse"
          children={
            <>
              <text style={{ marginLeft: 12 }}>
                <text
                  className={classes.checKvkkUnderline}
                  onClick={handleClickOpen(
                    "paper",
                    policysState.terms.value,
                    policysState.terms.policysVerison,
                    policysState.terms.title,
                    policysState.terms.policyTitle
                  )}
                >
                  {t("register.terms.userTerms")}
                </text>
                {t("register.terms.and")}&nbsp;
                <text
                  className={classes.checKvkkUnderline}
                  onClick={handleClickOpen(
                    "paper",
                    policysState.clarificationOfTerms.value,
                    policysState.clarificationOfTerms.policysVerison,
                    policysState.clarificationOfTerms.title,
                    policysState.clarificationOfTerms.policyTitle
                  )}
                >
                  {t("register.terms.explicitConsent")}
                </text>
                {t("register.terms.acceptexplicitConsent")}
              </text>
              &nbsp;
            </>
          }
        />
        <CheckBox
          checked={paymentTerms.value}
          error={paymentTerms.error}
          helperText={t("register.error.required")}
          onChange={(e) =>
            handleChange(
              e,
              "paper",
              policysState.paymentTerms.value,
              policysState.paymentTerms.policysVerison,
              policysState.paymentTerms.title,
              policysState.paymentTerms.policyTitle
            )
          }
          name="paymentInstitution"
          children={
            <>
              <text style={{ marginLeft: 12 }}>
                <text
                  className={classes.checKvkkUnderline}
                  onClick={handleClickOpen(
                    "paper",
                    policysState.paymentTerms.value,
                    policysState.paymentTerms.policysVerison,
                    policysState.paymentTerms.title,
                    policysState.paymentTerms.policyTitle
                  )}
                >
                  {t("register.terms.paymentTerms")}
                </text>
                &nbsp;
                {t("register.terms.acceptUserAndPayment")}
              </text>
              &nbsp;
            </>
          }
        />
        <CheckBox
          checked={messageAprovalTerms.value}
          onChange={(e) =>
            handleChange(
              e,
              "paper",
              policysState.messageAprovalTerms.value,
              policysState.messageAprovalTerms.policysVerison,
              policysState.messageAprovalTerms.title,
              policysState.messageAprovalTerms.policyTitle
            )
          }
          name="messageConfirm"
          error={messageAprovalTerms.error}
          helperText={t("register.error.required")}
          children={
            <>
              <div style={{ marginLeft: 12 }}>
                <div>
                  <text>
                    <text
                      className={classes.checKvkkUnderline}
                      onClick={handleClickOpen(
                        "paper",
                        policysState.messageAprovalTerms.value,
                        policysState.messageAprovalTerms.policysVerison,
                        policysState.messageAprovalTerms.title,
                        policysState.messageAprovalTerms.policyTitle
                      )}
                    >
                      {t("register.terms.messageConfirmation")}
                    </text>
                    &nbsp;
                    {t("register.terms.acceptMessageConfirmation")}
                  </text>
                </div>
              </div>
              &nbsp;
            </>
          }
        />

        <Grid className={classes.checKvkk} item>
          <Grid className={classes.infoLeft}>
            <div>
              <div>
                <text>
                  <text
                    className={classes.checKvkkUnderline}
                    onClick={handleClickOpen(
                      "paper",
                      policysState.depositApplicationPrivacyStatement.value,
                      policysState.depositApplicationPrivacyStatement
                        .policysVerison,
                      policysState.depositApplicationPrivacyStatement.title,
                      policysState.depositApplicationPrivacyStatement
                        .policyTitle
                    )}
                  >
                    {t("register.terms.depositInformation")}
                  </text>
                  {t("register.terms.andInformatin")}&nbsp;
                  <text
                    className={classes.checKvkkUnderline}
                    onClick={handleClickOpen(
                      "paper",
                      policysState.paymentApplicationPrivacyStatement.value,
                      policysState.paymentApplicationPrivacyStatement
                        .policysVerison,
                      policysState.paymentApplicationPrivacyStatement.title,
                      policysState.paymentApplicationPrivacyStatement
                        .policyTitle
                    )}
                  >
                    {t("register.terms.paymentInformation")}
                  </text>
                  {t("register.terms.acceptInformation")}
                </text>
              </div>
            </div>
            &nbsp;
          </Grid>
        </Grid>
        <Grid className={classes.checKvkk} item>
          <Button
            type="tertiary"
            variant="outlined"
            className={classes.registerButton}
            onClick={() => {
              handleRegister();
            }}
          >
            {t("register.registerButton")}
          </Button>
        </Grid>
        <ScrollModal
          scroll={scroll}
          open={open}
          policys={policys}
          policysVerison={policysVersion}
          modalTitle={modalTitle}
          policysTitle={policysTitle}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          handleOkey={(policys: any) => {
            modalHandleOkey(policys);
          }}
        />
      </Box>
    </Layout>
  );
}
