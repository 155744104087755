import { customApiRequest } from "../util/httpUtils";

const lang = localStorage.getItem("language");

export async function createPin(body: object) {
  const options = {
    method: "POST",
    url: "https://tabiat.ekomobil.com.tr/dbys/v2/auth/create-pin",
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": lang,
    },
    data: body,
  };
  const result = await customApiRequest(options);

  return result;
}

export async function resetPin(body: object) {
  const accessToken: string | null = localStorage.getItem("access_token");
  const options = {
    method: "POST",
    url: "https://tabiat.ekomobil.com.tr/dbys/v1/auth/reset-pin",
    headers: {
      Authorization: accessToken,
      "Content-Type": "application/json",
      "Accept-Language": lang,
    },
    data: body,
  };
  const result = await customApiRequest(options);

  return result;
}

export async function updatePin(body: object) {
  const accessToken: string | null = localStorage.getItem("access_token");
  const options = {
    method: "POST",
    url: "https://tabiat.ekomobil.com.tr/dbys/v1/auth/update-pin",
    headers: {
      Authorization: accessToken,
      "Content-Type": "application/json",
      "Accept-Language": lang,
    },
    data: body,
  };
  const result = await customApiRequest(options);
  return result;
}
