import { customApiRequest } from "../util/httpUtils";

const lang = localStorage.getItem("language");

export async function emailUpate(body: object) {
  const accessToken: string | null = localStorage.getItem("access_token");
  const options = {
    method: "PUT",
    url: "https://tabiat.ekomobil.com.tr/dbys/v1/users/email",
    headers: {
      Authorization: accessToken,
      "Content-Type": "application/json",
      "Accept-Language": lang,
    },
    data: body,
  };
  const result = await customApiRequest(options);
  return result;
}
