import Button from "../Button/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid } from "@mui/material";
import { tss } from "tss-react";
import CloseIcon from "../../icons/close.svg";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import React, { useState } from "react";

type Props = {
  handleClose?: any;
  handleOkey?: any;
  open?: any;
  scroll?: any;
  title?: any;
  policys?: any;
  policysVerison?: any;
  policysTitle?: any;
  modalTitle?: any;
  children?: any;
};

const useStyles = tss
  .withParams<{
    customTheme: any;
  }>()
  .create(({ customTheme }) => ({
    root: {
      width: customTheme.customDesktop["desktop-columns"].md["col-5"].value,
      backgroundColor: customTheme.custom.color.surface.white.value,
      padding: 0,
      alignItems: "center",
      borderRadius: customTheme.custom.radius.xl.value,
    },
    headerContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      padding: customTheme.custom.spacing.md.value,
    },
    headerTitle: {
      textAlign: "center",
      color: customTheme.custom.color.text.primary.value,
      fontSize: 16,
      fontWeight: "500",
      lineHeight: "150%",
    },
    closeIcon: {
      cursor: "pointer",
      ":hover": {
        backgroundColor: "white",
      },
    },
    w100: {
      width: "100%",
    },
  }));

export default function Modal(props: Props) {
  const { handleClose, handleOkey, open, title, modalTitle } = props;
  const [disable, setDisable] = useState(true);
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  const { classes, cx } = useStyles({ customTheme: theme });

  // Attach the scroll listener to the div
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Box className={classes.root}>
          <Grid item xs={12}>
            <div className={classes.headerContainer}>
              <img
                className={classes.closeIcon}
                src={CloseIcon}
                onClick={handleClose}
              />
              <div className={classes.w100}>
                <div className={classes.headerTitle}>{modalTitle}</div>
              </div>
            </div>
          </Grid>
          {props.children}
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
