import * as React from "react";
import Button from "../../Button/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid } from "@mui/material";
import Tooltip from "../../Tooltip/Tooltip";
import { tss } from "tss-react";
import CloseIcon from "../../../icons/close.svg";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";

type Props = {
  bodyText?: string;
  handleClickOpen?: any;
  handleClose?: any;
  handleOkey?: any;
  open?: any;
  scroll?: any;
  title?: any;
  policys?: any;
  policysVerison?: any;
  policysTitle?: any;
  modalTitle?: any;
};

const useStyles = tss
  .withParams<{
    customTheme: any;
  }>()
  .create(({ customTheme }) => ({
    root: {
      maxWidth: 680,
      maxHeight: 470,
      backgroundColor: customTheme.custom.color.surface.white.value,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyItems: "center",
      justifyContent: "center",
      borderRadius: 24,
    },
    scroolBox: {
      backgroundColor: customTheme.custom.color.surface.white.value,
      height: 616,
      flex: 1,
      overflow: "auto",
      marginLeft: 32,
      marginRight: 32,
    },
    headerDialog: {
      alignItems: "center",
      width: "100%",
      display: "flex",
      justifyItems: "center",
      justifyContent: "center",
      borderBottom: "solid",
      borderColor: customTheme.custom.color.outline.default.value,
      paddingBottom: 10,
    },
    headerTitle: {
      textAlign: "center",
      color: customTheme.custom.color.text.primary.value,
      fontSize: 20,
      fontFamily: "Poppins",
      fontWeight: "500",

      letterSpacing: 0.15,
      wordWrap: "break-word",
    },
    closeIcon: {
      position: "absolute",
      left: 27,
      padding: 8,
      ":hover": {
        backgroundColor: "#F5F5F5",
        borderRadius: 50,
      },
    },
    bottomDialog: {
      padding: 15,
      borderTop: "solid",
      borderColor: customTheme.custom.color.outline.default.value,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      borderWidth: 1,
    },
    bottomButton: {
      width: "368px",
    },
    title: {
      textAlign: "center",
      color: customTheme.custom.color.text.primary.value,
      fontSize: 24,
      marginLeft: 20,
      marginRight: 20,
      fontFamily: "Poppins",
      fontWeight: "500",
      wordWrap: "break-word",
      marginBottom: 4,
      marginTop: 4,
    },
    body: {
      color: "black",
      fontSize: 16,
      padding: 4,
      fontFamily: "Poppins",
      fontWeight: "400",
      width: "500px",
    },
  }));

export default function ScrollModal(props: Props) {
  const {
    bodyText,
    handleClickOpen,
    handleClose,
    handleOkey,
    open,
    scroll,
    title,
    policys,
    policysVerison,
    modalTitle,
    policysTitle,
  } = props;
  const [disable, setDisable] = React.useState(true);
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  React.useEffect(() => {
    setDisable(true);
  }, [open]);
  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleScroll = (e: any) => {
    var policysHeight = policysTitle === "paymentTerms" ? 1 : 2;

    const bottom =
      e.target.scrollHeight - e.target.scrollTop - policysHeight <
      e.target.clientHeight;

    if (bottom) {
      setDisable(false);
    }
  };
  const { classes, cx } = useStyles({ customTheme: theme });

  const iframeref = React.useRef<any>(null);

  const [html, setHTML] = React.useState({ __html: "" });

  function policysLang(lang: any) {
    switch (lang) {
      case "tr":
        return `https://objectstore.ekomobil.com.tr/agreements-pb/v${policysVerison}/${policys}.html`;
      case "en":
        return `https://objectstore.ekomobil.com.tr/agreements-pb/v${policysVerison}/${policys}_en.html`;

      default:
        return `https://objectstore.ekomobil.com.tr/agreements-pb/v${policysVerison}/${policys}_en.html`;
    }
  }

  React.useEffect(() => {
    if (modalTitle === "Depozito Açık Rıza Metni") {
      setDisable(false);
    }
    async function createMarkup() {
      let response;
      let backendHtmlString;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "text/plain; charset=UTF-8");
      response = await fetch(policysLang(i18n.language), {
        headers: myHeaders,
      })
        .then(function (response) {
          return response.arrayBuffer();
        })
        .then(function (buffer) {
          const decoder = new TextDecoder("iso-8859-1");
          const text = decoder.decode(buffer);
          backendHtmlString = text;
        });

      // backendHtmlString = await response.text();
      return { __html: backendHtmlString };
    }
    createMarkup().then((result: any) => setHTML(result));
  }, [policysVerison, policys]);

  // Attach the scroll listener to the div
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Box className={classes.root}>
          <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
          <Grid className={classes.headerDialog}>
            <Grid className={classes.closeIcon}>
              <img
                style={{ cursor: "pointer" }}
                src={CloseIcon}
                onClick={handleClose}
              />
            </Grid>

            <Grid className={classes.headerTitle}>{t("" + modalTitle)}</Grid>
          </Grid>

          <Box className={classes.scroolBox} onScroll={handleScroll}>
            <div dangerouslySetInnerHTML={html} />
          </Box>
          <Grid className={classes.bottomDialog}>
            <Tooltip
              placement={"top"}
              title={!disable ? null : t("modal.agreementScroll")}
            >
              <Grid className={classes.bottomButton}>
                {modalTitle !== "Depozito Aydınlatma Metni" &&
                  modalTitle !== "Ödeme Kuruluşu Aydınlatma Metni" && (
                    <Button
                      disabled={disable}
                      type="tertiary"
                      variant="outlined"
                      onClick={() => handleOkey(policysTitle)}
                    >
                      {t("modal.okeyButton")}
                    </Button>
                  )}
              </Grid>
            </Tooltip>
          </Grid>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
