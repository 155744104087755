import { ChangeEvent, useEffect, useState } from "react";
import { Box, Grid, InputAdornment } from "@mui/material";
import TextField from "../../components/TextField/TextField";
import { tss } from "tss-react/mui";
import Layout from "../../components/Layout/Layout";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "../../components/Button/Button";
import PasswordEnter from "../Password/PasswordEnter";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { sendUsersDelete } from "../../services/usersDelete";

type Props = {};

const useStyles = tss
  .withParams<{
    customTheme: any;
  }>()
  .create(({ customTheme }) => ({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      justifyContent: "center",
    },
    stepOneContainer: {
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "column",
    },
    headerDesc: {
      color: customTheme.custom.color.text.secondary.value,
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "150%",
      paddingBottom: customTheme.custom.spacing.xs.value,
      justifyContent: "center",
      display: "flex",
    },
    radio: {
      "&.MuiRadio-root": {
        color: customTheme.custom.color.input.stroke.default.value,
        borderRadius: customTheme.custom.radius.full.value,
      },
      "&.Mui-checked": {
        color: customTheme.custom.color.input.stroke.brand.value,
        borderRadius: customTheme.custom.radius.full.value,
      },
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
    },
    button: {
      display: "flex",
      justifyContent: "center",
      paddingTop: customTheme.custom.spacing.lg.value,
    },
  }));

function DeleteAccount(props: Props) {
  const theme: any = useTheme();
  const { classes, cx } = useStyles({
    customTheme: theme,
  });
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [removeAccountDsc, setRemoveAccountDsc] = useState(
    location.state?.removeAccountDsc ? location.state.removeAccountDsc : ""
  );
  const [deleteReasonId, setDeleteReasonId] = useState(
    location.state?.deleteReasonId ? location.state.deleteReasonId : ""
  );
  const [step, setStep] = useState(
    location.state?.step ? location.state.step : 0
  );
  const [radioButtonArray, setRadioButtonArray] = useState([
    { label: t("deleteAccount.deleteNotSatısfied"), value: "1" },
    { label: t("deleteAccount.deleteRequest"), value: "2" },
    { label: t("deleteAccount.deleteOther"), value: "3" },
  ]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeleteReasonId((event.target as HTMLInputElement).value);
  };

  const goToStep = (stepNo: any) => {
    navigate(`/deleteAccount`, {
      state: {
        step: stepNo,
        deleteReasonId: deleteReasonId,
        removeAccountDsc: removeAccountDsc,
      },
    });
    setStep(stepNo);
  };

  const handleContinue = () => {
    if (deleteReasonId) {
      if (deleteReasonId == "3") {
        if (removeAccountDsc) {
          goToStep(1);
        } else {
          // Error mesaj
        }
      } else {
        goToStep(1);
      }
    }
  };
  const passwordEnterCountinue = async (pass: string) => {
    let dsc;
    if (removeAccountDsc) {
      dsc = removeAccountDsc;
    } else {
      dsc = radioButtonArray[parseInt(deleteReasonId) - 1].label;
    }
    const result = await sendUsersDelete({
      deleteReasonId: parseInt(deleteReasonId),
      pin: pass,
      description: dsc,
    }).then((response) => {
      return response.data ? response.data : response;
    });
    if (result.status === 200) {
      navigate(`/deleteAccountSucces`);
    }
  };

  switch (step) {
    case 0:
      return (
        <Layout
          header={{
            title: t("deleteAccount.layoutHeader.title"),
          }}
          propPadding={`${theme.custom.spacing.xl.value} ${theme.custom.spacing.lg.value}`}
          layoutPadding={156}
          isLogin
        >
          <Grid className={classes.root}>
            <Grid item xs={8} className={classes.stepOneContainer}>
              <div className={classes.headerDesc}>
                {t("deleteAccount.header")}
              </div>
              <Grid item xs={12}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={deleteReasonId}
                  onChange={handleChange}
                  name="radio-buttons-group"
                >
                  {radioButtonArray.map((element, index) => (
                    <FormControlLabel
                      key={index}
                      value={element.value}
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                          }}
                        />
                      }
                      label={element.label}
                    />
                  ))}
                </RadioGroup>
                {deleteReasonId == "3" && (
                  <Grid item xs={10}>
                    <TextField
                      id="outlined-basic"
                      value={removeAccountDsc}
                      placeHolder={t("deleteAccount.otherTextPlaceHolder")}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setRemoveAccountDsc(event.target.value);
                      }}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} className={classes.buttonContainer}>
                <Grid item xs={8} className={classes.button}>
                  <Button
                    type="tertiary"
                    variant="contained"
                    text={t("deleteAccount.deleteAccountButtonText")}
                    onClick={handleContinue}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Layout>
      );
    case 1:
      return (
        <PasswordEnter
          header={{
            title: "t(password.headerEnter.title)",
            desc: "t(password.headerEnter.desc)",
          }}
          onClickContinue={passwordEnterCountinue}
          isLogin
        />
      );

    default:
      break;
  }

  return <></>;
}

export default DeleteAccount;
