import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker as DatePickerMui, trTR, enUS } from "@mui/x-date-pickers";
import { isSafari } from "../../util/devUtils";
import DateIcon from "../../icons/date.svg";
import ErrorIcon from "../../icons/errorIcon.svg";

import dayjs from "dayjs";
import "dayjs/locale/tr";
import { tss } from "tss-react/mui";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";

const trLocal = trTR.components.MuiLocalizationProvider.defaultProps.localeText;
const enLocal = enUS.components.MuiLocalizationProvider.defaultProps.localeText;
type Props = {
  id?: string;
  label?: string;
  value?: any;
  onChange?: any;
  header?: string;
  error?: boolean;
  disabled?: boolean;
  inputProps?: any;
  helperText?: string;
};

const useStyles = tss
  .withParams<{
    disabled: boolean | undefined;
    error: boolean | undefined;
    customTheme: any;
  }>()
  .create(({ theme, disabled, error, customTheme }) => ({
    datepicker: {
      width: "100%",
      fontFamily: "Poppins",
      ".MuiOutlinedInput-root": {
        borderRadius: 8,
      },

      "& .MuiOutlinedInput-notchedOutline": {
        border: error
          ? "2px solid " +
            customTheme.custom.color.input.stroke.error.value +
            "!important"
          : "1px solid " +
            customTheme.custom.color.input.stroke.default.value +
            " !important",
      },

      "& .MuiOutlinedInput-input": {
        fontWeight: "400",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: "0.00938em",
        fontFamily: "Poppins",
      },

      "& .MuiInputBase-input": {
        padding: "14px",
        fontWeight: "400",
        borderRadius: 8,
        fontSize: 16,
      },
      "&.MuiPickersDay-root.Mui-selected": {
        backgroundColor: customTheme.custom.color.input.stroke.brand.value,
      },

      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: disabled
          ? "#C7C7C7 !important"
          : error
          ? customTheme.custom.color.input.stroke.error.value + "!important"
          : "#141414 !important",
        border: disabled || error ? "1px solid" : "1px solid",
      },

      "& .Mui-error": {
        "& .MuiInputBase-input": {
          color: "#141414",
        },
        // borderColor: "blue !important",
        // border: "2px solid !important",
      },

      "& .Mui-focused": {
        outline: isSafari() ? "none" : "#141414 auto 2px",

        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      "& .MuiFormHelperText-root": {
        marginLeft: 0,
        outline: "none",
      },

      "MuiPickersLayout-root": {
        fontFamily: "Poppins",
      },

      "input#outlined-basic": {
        opacity: disabled ? 0 : 1,
      },

      ".Mui-disabled": {
        border: "1px solid #C7C7C7",
        backgroundColor: "#F5F5F5",
        " .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
    },
    inputTitle: {
      display: "flex",
      color: error
        ? customTheme.custom.color.input.stroke.error.value
        : disabled
        ? "#6E6E6E"
        : "#141414",
      textAlign: "left",
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "150%",
      marginBottom: 4,
    },
    helperTextContainer: {
      display: "flex",
      flexDirection: "row",
      verticalAlign: "center",
      color: customTheme.custom.color.input.stroke.error.value,
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 14,
      marginTop: 4,
    },
    helperText: {
      marginLeft: 8,
      marginTop: 2,
    },
    iconDate: {
      marginRight: 4.6,
    },
  }));

export default function DatePicker(props: Props) {
  const { label, id, value, onChange, header, error, disabled, helperText } =
    props;
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { classes, cx } = useStyles({
    disabled: disabled,
    error: error,
    customTheme: theme,
  });

  const CalendarIcon = () => (
    <img className={classes.iconDate} src={DateIcon} alt="Calendar" />
  );

  function localeText(lang: any) {
    switch (lang) {
      case "tr":
        return trLocal;
      case "en":
        return enLocal;
      default:
        return enLocal;
    }
  }

  return (
    <>
      <span className={classes.inputTitle}>{header}</span>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={i18n.language}
        localeText={localeText(i18n.language)}
      >
        <DatePickerMui
          className={classes.datepicker}
          value={value}
          onChange={onChange}
          slots={{
            openPickerIcon: CalendarIcon,
          }}
          slotProps={{
            day: {
              sx: {
                fontFamily: "Poppins !important",
                "& .MuiPickersDay-root": {
                  fontFamily: "Poppins",
                  backgroundColor: "#2D9D60",
                },
                "&.MuiPickersDay-root.Mui-selected": {
                  fontFamily: "Poppins",
                  backgroundColor: "#2D9D60",
                },
                "& .MuiPickersYear-yearButton.Mui-selected": {
                  backgroundColor: "#2D9D60",
                },
                ":not(.Mui-selected)": {
                  backgroundColor: "#fff",
                  borderColor: "#2D9D60",
                },
                "&:hover": {
                  backgroundColor: "#2D9D60",
                  borderColor: "#2D9D60",
                  transition: "all 0.5s ease",
                },
                "&.Mui-selected": {
                  color: "#fff",
                  backgroundColor: "#2D9D60",
                  borderColor: "#2D9D60",
                  "&:hover": {
                    color: "#fff",
                    backgroundColor: "#2D9D60",
                    borderColor: "#2D9D60",
                    transition: "all 0.5s ease",
                  },
                },
              },
            },
            desktopPaper: {
              sx: {
                fontFamily: "Poppins !important",
                ".MuiPickersYear-yearButton.Mui-selected": {
                  backgroundColor: "#2D9D60",
                  fontFamily: "Poppins",
                  "&:hover": {
                    backgroundColor: "#2D9D60",
                    fontFamily: "Poppins !important",
                    transition: "all 0.5s ease",
                  },
                  ":not(.Mui-selected)": {
                    backgroundColor: "#fff",
                    borderColor: "#2D9D60",
                  },
                },

                ".MuiPickersMonth-monthButton.Mui-selected": {
                  backgroundColor: "#2D9D60",
                  fontFamily: "Poppins",
                },
                "&:hover": {
                  fontFamily: "Poppins !important",
                  transition: "all 0.5s ease",
                },
              },
            },
            toolbar: {
              sx: {
                fontFamily: "Poppins",
              },
            },
            actionBar: {
              sx: {
                fontFamily: "Poppins",
                "& button": {
                  color: "black",
                },
              },
            },
          }}
          maxDate={dayjs().subtract(18, "years")}
          format="DD.MM.YYYY"
        />
        <div>
          {helperText && (
            <div className={classes.helperTextContainer}>
              <img src={ErrorIcon} alt="err" />
              <span className={classes.helperText}>{helperText}</span>
            </div>
          )}
        </div>
      </LocalizationProvider>
    </>
  );
}
