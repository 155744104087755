import {
  Select as SelectMui,
  SelectChangeEvent,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { ReactNode } from "react";
import { tss } from "tss-react/mui";
import DownArrow from "../../icons/downArrow.svg";
import DisabledDownArrow from "../../icons/disabledDownArrow.svg";
import ErrorIcon from "../../icons/errorIcon.svg";
import { useTheme } from "@emotion/react";

type Props = {
  checked?: boolean;
  name?: string;
  defaultChecked?: boolean;
  className?: any;
  value?: string;
  labelId?: string;
  id?: string;
  onChange?:
    | ((event: SelectChangeEvent<unknown>, child: ReactNode) => void)
    | undefined;
  menuItemList?: Array<any>;
  inputClassName?: string;
  labelText?: string;
  error?: boolean;
  errorText?: string;
  inputProps?: Object;
  header?: string;
  disabled?: boolean;
};

const useStyles = tss
  .withParams<{
    error: boolean | undefined;
    disabled: boolean | undefined;
    customTheme: any;
  }>()
  .create(({ theme, error, disabled, customTheme }) => ({
    selectMui: {
      fontSize: 16,
      height: 49,
      fontStyle: "normal",
      borderRadius: customTheme.custom.radius.md.value,
      backgroundColor: disabled
        ? customTheme.custom.color.input.surface.disabled.value
        : customTheme.custom.color.input.surface.default.value,
      "& .MuiOutlinedInput-notchedOutline": {
        border: error ? "2px solid" : "1px solid #A8A8A8",
        borderColor: error
          ? ""
          : customTheme.custom.color.input.stroke.default.value,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: disabled
          ? customTheme.custom.color.input.stroke.disabled.value + " !important"
          : error
          ? customTheme.custom.color.input.stroke.error.value + " !important"
          : customTheme.custom.color.input.stroke.hover.value + " !important",
        border: error ? "2px solid" : "1px solid",
      },
      "& label": {
        fontSize: 16,
      },
      "& .MuiInputLabel-shrink": {
        opacity: 0,
      },
      "& .MuiSelect-select": {
        fontSize: 16,
        textAlign: "left",
        color: customTheme.custom.color.text.primary.value,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#242628",
      },
      "&.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: customTheme.custom.color.input.stroke.error.value,
      },
      ".Mui-disabled": {
        color: customTheme.custom.color.input.stroke.default.value,
        "-webkit-text-fill-color":
          customTheme.custom.color.text.disabled.value + " !important",
      },
    },
    menuItem: {
      "&.MuiMenuItem-gutters": {
        fontSize: 16,
      },
    },
    inputLabel: {
      "& label": {
        fontSize: 16,
        fontWeight: 400,
        color: error
          ? customTheme.custom.color.text.error.value
          : customTheme.custom.color.text.primary.value,
      },
      "& .MuiInputLabel-shrink": {
        opacity: 0,
      },
    },
    errorText: {
      fontSize: 14,
      color: customTheme.custom.color.text.error.value,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "150%",
      marginTop: 4,
      fontFamily: "Poppins",
      marginLeft: 0,
    },
    inputTitle: {
      display: "flex",
      color: error
        ? customTheme.custom.color.text.error.value
        : customTheme.custom.color.text.primary.value,
      textAlign: "left",
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "150%",
      marginBottom: 4,
    },
    helperTextContainer: {
      display: "flex",
      flexDirection: "row",
      verticalAlign: "center",
    },
    helperText: {
      marginLeft: customTheme.custom.spacing.xs.value,
      marginTop: customTheme.custom.spacing.xxs.value,
    },
  }));

function Select(props: Props) {
  const theme = useTheme();
  const {
    onChange,
    value,
    defaultChecked,
    className,
    id,
    labelId,
    menuItemList,
    inputClassName,
    labelText,
    error,
    errorText,
    inputProps,
    header,
    disabled,
  } = props;
  const { classes, cx } = useStyles({
    error: error,
    disabled: disabled,
    customTheme: theme,
  });

  const menuList: any = [];
  menuItemList?.forEach((item: any) => {
    menuList.push(
      <MenuItem key={item.id} className={classes.menuItem} value={item.id}>
        {item.name}
      </MenuItem>
    );
  });
  return (
    <>
      <span className={classes.inputTitle}>{header}</span>
      <FormControl fullWidth className={cx(classes.inputLabel, inputClassName)}>
        <InputLabel id={id}>{labelText}</InputLabel>
        <SelectMui
          id={id}
          labelId={labelId}
          className={cx(classes.selectMui, className)}
          onChange={onChange}
          value={value}
          defaultChecked={defaultChecked}
          error={error}
          inputProps={inputProps}
          IconComponent={() => (
            <img src={disabled ? DisabledDownArrow : DownArrow} />
          )}
          disabled={disabled}
        >
          {menuList}
        </SelectMui>
        {errorText && (
          <FormHelperText className={classes.errorText}>
            <div className={classes.helperTextContainer}>
              <img src={ErrorIcon} alt="err" />
              <span className={classes.helperText}>{errorText}</span>
            </div>
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
}

export default Select;
