import { useTranslation } from "react-i18next";
import { Box, Grid, InputAdornment } from "@mui/material";
import { tss } from "tss-react/mui";
import TextField from "../../components/TextField/TextField";
import { ChangeEvent, useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import CustomSlider from "../../components/CustomSlider/Slider";
import LogoDepozito from "../../icons/logo-depozito.svg";
import LangIcon from "../../icons/langIcon.svg";
import LogoPartners from "../../icons/logo-partners.svg";
import LoginImg from "../../icons/loginImg.svg";
import { NumericFormatMobilePhone } from "../../constants/functions";
import { useTheme } from "@emotion/react";
import LoginHeader from "../../components/LoginHeader/LoginHeader";

type Props = {
  onClickLogin?: (phoneNumber?: string) => {};
};

const useStyles = tss
  .withParams<{
    customTheme: any;
  }>()
  .create(({ theme, customTheme }) => ({
    box: {
      flexGrow: 1,
      alignItems: "center",
    },
    root: {
      width: "100%",
      padding: customTheme.custom.spacing.xl.value,
      minHeight: "100vh",
    },
    outsideBox: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: customTheme.custom.color.surface.white.value,
      padding: customTheme.custom.spacing.lg.value,
      borderRadius: customTheme.custom.radius.xl.value,
    },
    loginHeader: {
      display: "flex",
      flexDirection: "row",
      padding: customTheme.custom.spacing.md.value,
      justifyContent: "space-between",
      alignItems: "center",
    },
    headerSubButton: {
      display: "flex",
      color: customTheme.custom.color.text.primary.value,
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 500,
    },
    subContainer: {
      display: "flex",
      flexDirection: "row",
      marginTop: 56,
      justifyContent: "center",
      paddingLeft: customTheme.custom.spacing["2xl"].value,
      paddingRight: customTheme.custom.spacing["2xl"].value,
      alignItems: "center",
    },
    langText: {
      display: "flex",
      alignItems: "center",
      marginLeft: customTheme.custom.spacing.lg.value,
    },
    subLangText: {
      marginLeft: customTheme.custom.spacing.xs.value,
    },
    title: {
      color: customTheme.custom.color.text.primary.value,
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "130%",
      letterSpacing: "1px",
    },
    subTitle: {
      color: customTheme.custom.color.text.secondary.value,
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "150%",
      letterSpacing: "0.16px",
      paddingTop: customTheme.custom.spacing.xs.value,
      paddingBottom: customTheme.custom.spacing.lg.value,
    },
    loginButton: {
      paddingTop: customTheme.custom.spacing.lg.value,
    },
    rightSide: {
      paddingTop: customTheme.custom.spacing["2xl"].value,
      paddingBottom: customTheme.custom.spacing["2xl"].value,
      paddingLeft: customTheme.custom.spacing.xl.value,
      paddingRight: customTheme.custom.spacing.xl.value,
      borderRadius: customTheme.custom.radius.xl.value,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: customTheme.custom.color.outline.default.value,
      maxWidth: "504px !important",
      marginLeft: "auto",
      height: "fit-content",
      [theme.breakpoints.down("lg")]: {
        marginLeft: 0,
        marginRight: 0,
        marginTop: customTheme.custom.spacing.xl.value,
        marginBottom: customTheme.custom.spacing.xl.value,
      },
    },
    leftSide: {
      [theme.breakpoints.down("lg")]: {
        marginRight: 0,
        marginLeft: 0,
      },
    },
    logoPartners: {
      marginTop: "auto",
    },
    stepperContain: {
      display: "flex",
      flexDirection: "column",
    },
    containText: {
      color: customTheme.custom.color.text.primary.value,
      fontSize: 32,
      fontStyle: "normal",
      fontWeight: 500,
      fontHeight: "130%",
      letterSpacing: "-0.64px",
      marginBottom: 32,
    },
    imgStyle: {
      width: "100%",
    },
    langButton: {
      padding: 0,
      color: customTheme.custom.color.text.primary.value,
    },
  }));

function Login(props: Props) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const { classes } = useStyles({
    customTheme: theme,
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errPhoneNumber, setErrPhoneNumber] = useState(false);
  const [errTextPhoneNumber, setErrTextPhoneNumber] = useState("");

  useEffect(() => {
    window.history.replaceState(null, "", "/");
  }, []);

  const handleLogin = async () => {
    props.onClickLogin && props.onClickLogin(phoneNumber);
  };

  const steps = [
    <div className={classes.stepperContain}>
      <div className={classes.containText}>
        Depozito iadeleriniz burada avantaja dönüşüyor!
      </div>
      <img src={LoginImg} alt="Darphane Logo" className={classes.imgStyle} />
    </div>,
    <div className={classes.stepperContain}>
      <div className={classes.containText}>
        Depozito iadeleriniz burada avantaja dönüşüyor!
      </div>
      <img src={LoginImg} alt="Darphane Logo" className={classes.imgStyle} />
    </div>,
    <div className={classes.stepperContain}>
      <div className={classes.containText}>
        Depozito iadeleriniz burada avantaja dönüşüyor!
      </div>
      <img src={LoginImg} alt="Darphane Logo" className={classes.imgStyle} />
    </div>,
    <div className={classes.stepperContain}>
      <div className={classes.containText}>
        Depozito iadeleriniz burada avantaja dönüşüyor!
      </div>
      <img src={LoginImg} alt="Darphane Logo" className={classes.imgStyle} />
    </div>,
    "",
  ];

  const handleChangeLang = () => {
    const lang = localStorage.getItem("language");
    const isTurkish = lang === "tr" || lang === null;
    i18n.changeLanguage(isTurkish ? "en" : "tr");
    localStorage.setItem("language", isTurkish ? "en" : "tr");
  };

  function handleKeyPress(event: any) {
    if (event.key === "Enter") {
      handleLogin();
    }
  }

  return (
    <Box className={classes.box}>
      <Grid container className={classes.root}>
        <div className={classes.outsideBox}>
          <div className={classes.loginHeader}>
            <img src={LogoDepozito} alt="logo-depozito" />
            <div className={classes.headerSubButton}>
              <div> {t("login.header.help")}</div>
              <div className={classes.langText}>
                <Button
                  className={classes.langButton}
                  type="text"
                  variant="contained"
                  text={t("login.header.lang")}
                  startIcon={<img src={LangIcon} alt="lang-icon" />}
                  onClick={handleChangeLang}
                />
              </div>
            </div>
          </div>
          <Grid container className={classes.subContainer}>
            <Grid item className={classes.leftSide} xs={12} md={12} lg={6}>
              <CustomSlider steps={steps}></CustomSlider>
            </Grid>
            <Grid className={classes.rightSide} item xs={12} md={12} lg={6}>
              <Grid item xs={12}>
                <div className={classes.title}>{t("login.welcome")}</div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.subTitle}>{t("login.desc")}</div>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onKeyDown={handleKeyPress}
                  header={t("login.textHeader")}
                  id="outlined-basic"
                  value={phoneNumber}
                  error={errPhoneNumber}
                  helperText={errTextPhoneNumber}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setErrTextPhoneNumber("");
                    setErrPhoneNumber(false);
                    setPhoneNumber(event.target.value);
                  }}
                  phoneNumberPrefix
                  inputProps={{
                    inputComponent: NumericFormatMobilePhone as any,
                    startAdornment: (
                      <InputAdornment position="start">+90</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} className={classes.loginButton}>
                <Button
                  type="tertiary"
                  variant="contained"
                  text={t("login.button")}
                  onClick={handleLogin}
                />
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.logoPartners}>
            <img src={LogoPartners} alt="logo-partners" />
          </div>
        </div>
      </Grid>
    </Box>
  );
}

export default Login;
