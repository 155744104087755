import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from './reducers/profileReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['profile'], // Whitelist (Kaydetmek istediğiniz spesifik bir reducer değişkenini içine yazabilirsiniz)
  blacklist: [], // Blacklist (Kaydetmek istemediğiniz spesifik bir reducer değişkenini içine yazabilirsiniz)
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(
  persistedReducer
  // composeEnhancer(
  //   applyMiddleware(sagaMiddleware),
  // ),
);

// Middleware: persist store oluşturuyoruz.
export const persistor = persistStore(store);

// export const store = createStore(reducers, {}, applyMiddleware(thunk));
// export type AppDispatch = typeof store.dispatch;
// export type RootState = ReturnType<typeof store.getState>;
