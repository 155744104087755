import { KeyboardEventHandler, useState } from "react";
import Layout from "../../components/Layout/Layout";
import EnterOTP from "../../components/OTP/EnterOTP";
import { useTranslation } from "react-i18next";
import { tss } from "tss-react/mui";
import { Grid } from "@mui/material";
import Button from "../../components/Button/Button";

type Props = {
  type: string;
  header: { title: string; dsc: string };
  buttonTertiary?: {
    buttonTertiaryTitle: string;
    buttonTertiaryPress: Function;
  };
  buttonSecondary?: {
    buttonSecondaryTitle: string;
    buttonSecondaryPress: Function;
  };
  buttonText?: {
    buttonTextTitle: string;
    buttonTextPress: Function;
  };
  resultOfService?: any;
  handleSendAgainOtp: Function;
  onKeyDown?: any;
};

const useStyles = tss.create(({ theme }) => ({
  otpContainer: {
    display: "flex",
    marginTop: 4,
    justifyContent: "center",
  },
  button: {
    display: "flex",
    justifyContent: "center",
  },
  mt24: {
    marginTop: 24,
  },
}));

export default function OtpPages(props: Props) {
  const { classes } = useStyles();
  const [otpValue, setOtpValue] = useState("");
  const [errorOtp, setErrorOtp] = useState(false);
  const [verificationCodeErrorText, setVerificationCodeErrorText] =
    useState("");

  const sendAgainOtp = async () => {
    setVerificationCodeErrorText("");
    props.handleSendAgainOtp && props.handleSendAgainOtp(props.type);
  };

  const handleButtonClick = async () => {
    const post: any =
      props.buttonTertiary &&
      (await props.buttonTertiary?.buttonTertiaryPress(otpValue));

    if (post !== true && post && post.userDescription) {
      setVerificationCodeErrorText(post.userDescription);
      setErrorOtp(true);
    }
  };

  const secondaryPress = async () => {
    const post: any =
      props.buttonSecondary &&
      (await props.buttonSecondary?.buttonSecondaryPress("nextStep"));
  };

  const textPress = async () => {
    const post: any =
      props.buttonText && (await props.buttonText?.buttonTextPress());
  };
  return (
    <Layout
      header={{
        title: props.header.title,
        desc: props.header.dsc,
      }}
    >
      <Grid className={classes.otpContainer}>
        <EnterOTP
          type={props.type}
          remainingSeconds={props.resultOfService.remainingSeconds}
          handleChangeOtp={(value: string) => {
            setOtpValue(value);
            setErrorOtp(false);
            setVerificationCodeErrorText("");
          }}
          onKeyDown={(e: any) => props.onKeyDown(e, otpValue)}
          errorOtp={errorOtp}
          verificationCodeErrorText={verificationCodeErrorText}
          handleSendAgainOtp={sendAgainOtp}
        />
      </Grid>
      {props.buttonTertiary && (
        <Grid item xs={12} className={classes.button}>
          <Button
            type="tertiary"
            className={classes.mt24}
            onClick={() => {
              handleButtonClick();
            }}
          >
            {props.buttonTertiary.buttonTertiaryTitle}
          </Button>
        </Grid>
      )}
      {props.buttonSecondary && (
        <Grid item xs={12} className={classes.button}>
          <Button
            type="secondary"
            className={classes.mt24}
            onClick={() => {
              secondaryPress();
            }}
          >
            {props.buttonSecondary.buttonSecondaryTitle}
          </Button>
        </Grid>
      )}
      {props.buttonText && (
        <Grid item xs={12} className={classes.button}>
          <Button
            type="text"
            className={classes.mt24}
            onClick={() => {
              textPress();
            }}
          >
            {props.buttonText.buttonTextTitle}
          </Button>
        </Grid>
      )}
    </Layout>
  );
}
