import { toast } from "react-toastify";
import CloseIcon from "../../icons/close.svg";

export const CloseToastIcon = () => (
  <img
    style={{ position: "absolute", right: 9, top: 17, width: 16 }}
    src={CloseIcon}
    alt="Calendar"
  />
);

export const toastMessage = (
  title?: string,
  text?: string,
  color?: string,
  icon?: any
) =>
  toast(
    <div style={{ height: "100%", display: "flex", alignItems: "start" }}>
      <img src={icon} />
      <div
        style={{
          height: "100%",
          padding: 1,
          paddingLeft: 10,
          textAlign: "left",
          alignItems: "flex-start",
          marginRight: 10,
        }}
      >
        <div
          style={{
            flex: 1,
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "600",
            color: "#141414",
            paddingBottom: "4px",
          }}
        >
          {title}
        </div>
        <div
          style={{
            flex: 2,
            textAlign: "left",
            color: "#141414",
            fontFamily: "Poppins",
            fontWeight: "400",
            fontSize: "14px",
          }}
        >
          {text}
        </div>
      </div>
    </div>,
    {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: { background: color },
      closeButton: CloseToastIcon,
    }
  );
