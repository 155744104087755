import { useState } from "react";
import Register from "./Register";
import { register } from "../../services/registerServices";
import { sendEmailOtp, verifyEmailOtp } from "../../services/msisdnOtpServices";
import PasswordCreate from "../Password/PasswordCreate";
import { createPin } from "../../services/passwordServices";
import SecurityQuestions from "../SecurityQuestions/SecurityQuestions";
import { securityQuestion } from "../../services/securityQuestionsServices";
import errorToast from "../../components/Toast/ErrorToast";
import OtpPages from "../Otp/OtpPages";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

function RegisterProcess(props: any) {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const { t } = useTranslation();
  const [resultOfService, setResultOfService] = useState({
    otpId: "",
    email: "",
  });
  const [registrationToken, setRegistrationToken] = useState("");
  const [registerError, setRegisterToken] = useState();

  const handleClickRegister = async (body: any) => {
    const result = await register(body).then((result: any) => {
      return result;
    });
    if (result && result.otpId) {
      // TO DO email otp akisi kalktiginda burasi duzenlenecek
      setRegistrationToken(body.registrationToken);
      setResultOfService(result);
      setStep(1);
    } else if (result && result.status === 201) {
      setRegistrationToken(body.registrationToken);
      setStep(2);
    } else {
      setRegisterToken(result);
    }
  };

  const handleClickSendOtp = async (otpValue: string) => {
    if (otpValue === "nextStep") {
      setStep(2);
    } else {
      const body = {
        otpId: resultOfService.otpId,
        otpCode: otpValue,
        email: resultOfService.email,
        registrationToken: registrationToken,
      };

      const post = await verifyEmailOtp(body).then((response) => {
        return response;
      });
      if ((post && post === 204) || post === 200) {
        setStep(2);
      }
    }
  };

  const handleClickPasswordCreate = async (pass: string) => {
    const body = {
      newPin: pass,
      registrationToken: registrationToken,
    };
    const result = await createPin(body).then((result: any) => {
      return result;
    });
    if (result && result.status === 204) {
      setStep(3);
    }
  };

  const handleClickSecurityQuestions = async (id: string, answer: string) => {
    const body = {
      id,
      answer,
      registrationToken: registrationToken,
    };

    const result = await securityQuestion(body).then((response) => {
      return response;
    });

    if (result && result.tokenResource && result.tokenResource.walletToken) {
      localStorage.setItem("refresh_token", result.tokenResource.refreshToken);
      localStorage.setItem("access_token", result.tokenResource.accessToken);
      localStorage.setItem(
        "reset_pin_token",
        result.tokenResource.resetPinToken
      );
      localStorage.setItem("wallet_token", result.tokenResource.walletToken);
      // TO DO success main page
      navigate("/");
    }
  };

  const handleSendAgainOtp = async () => {
    const body = {
      email: resultOfService.email,
      registrationToken: registrationToken,
    };

    const result = await sendEmailOtp(body).then((response) => {
      return response;
    });
    if (result && result.otpId) {
      setResultOfService(result);
    }
  };
  switch (step) {
    case 0:
      return (
        <Register
          onClickRegister={handleClickRegister}
          msisdn={props.msisdn}
          registrationToken={props.registrationToken}
          error={registerError}
        />
      );
    case 1: // Email otp
      return (
        <OtpPages
          type="emailOpt"
          header={{
            title: t("mailOtp.headerTitle"),
            dsc: t("mailOtp.headerDesc", { userEmail: resultOfService.email }),
          }}
          resultOfService={resultOfService}
          handleSendAgainOtp={handleSendAgainOtp}
          buttonTertiary={{
            buttonTertiaryTitle: t("mailOtp.buttonTertiaryTitle"),
            buttonTertiaryPress: async (otpValue: string) => {
              return await handleClickSendOtp(otpValue);
            },
          }}
          buttonSecondary={{
            buttonSecondaryTitle: t("mailOtp.buttonSecondaryTitle"),
            buttonSecondaryPress: async (otpValue: string) => {
              return await handleClickSendOtp(otpValue);
            },
          }}
          onKeyDown={async (e: any, otpValue: any) => {
            if (e.key === "Enter") {
              return await handleClickSendOtp(otpValue);
            }
          }}
        />
      );
    case 2: // Password Create
      return (
        <PasswordCreate onClickPasswordCreate={handleClickPasswordCreate} />
      );
    case 3: // Security Question
      return (
        <SecurityQuestions
          registrationToken={registrationToken}
          onClickSecurityQuestions={handleClickSecurityQuestions}
        />
      );
    default:
      break;
  }

  return <></>;
}

export default RegisterProcess;
