export const errorTckn = (value: string) => {
  if (value.length === 0 || value.length < 11) {
    return true;
  }
  // case 4
  const sumOfOdd =
    (Number(value[0]) +
      Number(value[2]) +
      Number(value[4]) +
      Number(value[6]) +
      Number(value[8])) *
    7;
  const sumOfEven =
    Number(value[1]) + Number(value[3]) + Number(value[5]) + Number(value[7]);

  const result = (sumOfOdd - sumOfEven) % 10 === Number(value[9]);

  if (!result) {
    return true;
  }
  // case 5
  const sumOfTenElements =
    Number(value[0]) +
    Number(value[1]) +
    Number(value[2]) +
    Number(value[3]) +
    Number(value[4]) +
    Number(value[5]) +
    Number(value[6]) +
    Number(value[7]) +
    Number(value[8]) +
    Number(value[9]);

  const result2 = sumOfTenElements % 10 === Number(value[10]);

  if (!result2) {
    return true;
  }
  return false;
};

