import React, { useTransition } from "react";
import Layout from "../../components/Layout/Layout";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { tss } from "tss-react";
import RightIcon from "../../icons/rightIcon.svg";

import sendMoneyUser from "../../icons/sendMoneyUsers.svg";
import sendMoneyDeposit from "../../icons/sendMoneyDeposit.svg";
import sendMoneyIban from "../../icons/sendMoneyIban.svg";
import { Box, Grid } from "@mui/material";

const useStyles = tss
  .withParams<{
    customTheme: any;
  }>()
  .create(({ customTheme }) => ({
    root: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      justifyItems: "center",
      justifySelf: "center",
      flexDirection: "column",
    },
    container: {
      width: "100%",
    },
    mainTitle: {
      marginLeft: "13.3565%", // layoutPadding ? layoutPadding : 244
      marginRight: "13.3565%", // layoutPadding ? layoutPadding : 244
      color: "#141414",
      fontSize: 32,
      fontFamily: "Poppins",
      fontWeight: "500",
      wordWrap: "break-word",
      textAlign: "start",
    },
    headerContainer: {
      marginLeft: "13.3565%", // layoutPadding ? layoutPadding : 244
      marginRight: "13.3565%", // layoutPadding ? layoutPadding : 244
      display: "grid",
      gridTemplateColumns: "auto auto auto",
      gap: customTheme.custom.spacing.md.value,
      marginTop: 16,
    },
    card: {
      height: 140,
      padding: 16,
      background: "white",
      boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.20)",
      borderRadius: 16,
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
      display: "inline-flex",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#F5F5F5",
      },
    },
    cardIconContainer: {
      width: 34,
      height: 34,
      padding: 8,
      background: "#D5EBDF",
      borderRadius: 100,
      justifyContent: "center",
      alignItems: "center",
      gap: 8,
      display: "inline-flex",
    },
    cardTitle: {
      color: "#141414",
      fontSize: 18,
      fontFamily: "Poppins",
      fontWeight: "500",
      wordWrap: "break-word",
    },
    lastTransferContainer: {
      height: 405,
      background: "white",
      borderRadius: 24,
      marginTop: 24,
      padding: customTheme.custom.spacing.lg.value,
      marginLeft: "13.3565%", // layoutPadding ? layoutPadding : 244
      marginRight: "13.3565%", // layoutPadding ? layoutPadding : 244
    },
    lastTransferContainerTitle: {
      width: 653,
      color: "#141414",
      fontSize: 20,
      fontFamily: "Poppins",
      fontWeight: "500",
      letterSpacing: 0.15,
      wordWrap: "break-word",
      textAlign: "start",
    },
    userInfo: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
      cursor: "pointer",

      ":hover": {
        backgroundColor: "#F5F5F5",
      },
    },

    userContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      paddingLeft: customTheme.custom.spacing.lg.value,
      paddingTop: customTheme.custom.spacing.lg.value,
      paddingBottom: customTheme.custom.spacing.lg.value,
    },
    userInfoTitle: {
      color: customTheme.custom.color.text.primary.value,
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "150%",
    },
    userInfoDesc: {
      color: customTheme.custom.color.text.primary.value,
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "150%",
    },
    userInfoEmail: {
      color: customTheme.custom.color.text.disabled.value,
      fontSize: 16,
      fontStyle: "italic",
      fontWeight: 400,
      lineHeight: "150%",
    },
    withoutBorder: {
      border: "none",
    },
    imgClass: {
      width: 24,
      height: 24,
      paddingRight: customTheme.custom.spacing.lg.value,
    },
    modalRect: {
      padding: customTheme.custom.spacing.lg.value,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    modalDesc: {
      color: customTheme.custom.color.text.secondary.value,
      textAlign: "center",
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "150%",
      marginBottom: customTheme.custom.spacing.lg.value,
    },
    modalImg: {
      width: 160,
      height: 160,
      marginBottom: customTheme.custom.spacing.lg.value,
    },
    buttonAddPicture: {
      width: "calc(100% - 58px)",
    },
    inputImageUpload: {
      visibility: "hidden",
      position: "absolute",
    },
    helperTextContainer: {
      display: "flex",
      flexDirection: "row",
      verticalAlign: "center",
      marginTop: customTheme.custom.spacing.lg.value,
      marginBottom: customTheme.custom.spacing.md.value,
    },
    helperText: {
      color: customTheme.custom.color.text.error.value,
      marginLeft: customTheme.custom.spacing.xs.value,
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "150%",
    },
    selectedImage: {
      width: 160,
      height: 160,
      borderRadius: 240,
      marginBottom: 24,
    },
    selectedImageButtonWrap: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    ml24: {
      marginLeft: customTheme.custom.spacing.md.value,
    },
    userImage: {
      width: 64,
      height: 64,
      position: "absolute",
      left: 0,
      borderRadius: 240,
    },
    mobileRow: {
      display: "flex",
      flexDirection: "row",
    },
    mobileSuccesIcon: {
      marginLeft: 8,
      visibility: "visible",
    },
    w100: {
      width: "100%",
    },
    borderLine: {
      borderBottom: `1px solid ${customTheme.custom.color.outline.default.value}`,
      marginRight: customTheme.custom.spacing.lg.value,
      marginLeft: customTheme.custom.spacing.lg.value,
    },
    profileRect: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      border: `1px solid ${customTheme.custom.color.outline.default.value}`,
      borderRadius: customTheme.custom.radius.lg.value,
    },
  }));

export default function SendMoney() {
  const theme: any = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { classes, cx } = useStyles({ customTheme: theme });

  const moneySendHeader = [
    { label: "Kayıtlı Kişiye", icon: sendMoneyUser, _handleClick: () => {} },
    {
      label: "Depozito Hesabına",
      icon: sendMoneyDeposit,
      _handleClick: () => {},
    },
    { label: "Iban Numarasına", icon: sendMoneyIban, _handleClick: () => {} },
  ];

  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.mainTitle}>Para gönder</div>
          <Box className={classes.headerContainer}>
            {moneySendHeader.map((element: any) => (
              <Grid className={classes.card}>
                <div className={classes.cardIconContainer}>
                  <img src={element.icon} />
                </div>
                <div className={classes.cardTitle}>{element.label}</div>
              </Grid>
            ))}
          </Box>
          <Box className={classes.lastTransferContainer}>
            <div className={classes.lastTransferContainerTitle}>
              Son transfer yaptığınız kişiler
            </div>
            <Grid item xs={12}>
              <div className={classes.profileRect}>
                <div className={classes.userInfo}>
                  <div className={classes.userContainer}>
                    <div className={classes.userInfoTitle}>
                      Kişisel Bilgiler
                    </div>
                    <div className={classes.userInfoDesc}>asdasd</div>
                  </div>
                  <img
                    src={RightIcon}
                    className={classes.imgClass}
                    alt="right-icon"
                  />
                </div>
                <div className={classes.w100}>
                  <div className={classes.borderLine} />
                </div>
              </div>
            </Grid>
          </Box>
        </div>
      </div>
    </>
  );
}
