import { SET_PROFILE_STATE } from "../actionsType/profileType";

export const intialState = {
  name: "",
  ownerNameSurname: "",
  status: "",
  companyType: { id: 0 },
};

export default function profileReducer(state = intialState, action: any) {
  const actions = action;
  switch (actions.type) {
    case SET_PROFILE_STATE:
      return {
        ...state,
        profile: action.value,
      };
    default:
      return state;
  }
}
