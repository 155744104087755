import { Grid } from "@mui/material";
import { tss } from "tss-react/mui";
import Layout from "../../components/Layout/Layout";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import TextField from "../../components/TextField/TextField";
import LeftIcon from "../../icons/leftIcon.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

type Props = {};

const useStyles = tss
  .withParams<{
    customTheme: any;
  }>()
  .create(({ customTheme }) => ({
    mb16: {
      marginBottom: 16,
    },
  }));

function PersonalInformation(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const profile = useSelector((state: any) => state.profile);

  const { classes, cx } = useStyles({
    customTheme: theme,
  });

  return (
    <Layout
      header={{
        title: t("profile.personalInformation.header"),
        desc: t("profile.personalInformation.desc"),
      }}
      propPadding="32px 140px"
      isLogin
      isBack
      _handleBackAction={() => {
        navigate("/profile");
      }}
    >
      <Grid item xs={12}>
        <TextField
          header={t("register.name")}
          value={profile.name}
          className={classes.mb16}
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          header={t("register.surname")}
          value={profile.surname}
          className={classes.mb16}
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          header={t("register.identityNumber")}
          value={profile.trIdNumber}
          className={classes.mb16}
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          header={t("register.birtday")}
          value={profile.dateOfBirth}
          disabled
        />
      </Grid>
    </Layout>
  );
}

export default PersonalInformation;
