import { useState } from "react";
import { verifyLoginOtp } from "../../services/msisdnOtpServices";
import Login from "./Login";
import { sendLoginOtp, verifyPin } from "../../services/loginServices";
import PasswordEnter from "../Password/PasswordEnter";
import PasswordForgetProcess from "../Password/PasswordForgetProcess";
import RegisterProcess from "../Register/RegisterProcess";
import { useDispatch } from "react-redux";
import { setProfile } from "../../redux/actions/profileActions";
import OtpPages from "../Otp/OtpPages";
import { useTranslation } from "react-i18next";
import { getUserProfile } from "../../services/profileServices";
import { useNavigate } from "react-router";

function LoginProcess() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [resultOfService, setResultOfService] = useState({
    otpId: "",
    email: "",
    msisdn: "",
  });
  const [registrationToken, setRegistrationToken] = useState("");

  const handleClickLogin = async (phoneNumber?: string) => {
    const body = {
      username: phoneNumber,
    };
    const result = await sendLoginOtp(body).then((response) => {
      return response;
    });

    if (result.otpId) {
      setResultOfService(result);
      setStep(1);
    }
  };

  const handleClickSendOtp = async (otpValue: string) => {
    const body = {
      otpId: resultOfService.otpId,
      otpCode: otpValue,
      msisdn: resultOfService.msisdn,
    };
    const post = await verifyLoginOtp(body).then((response) => {
      return response;
    });
    if (post && post.registered) {
      dispatch(setProfile(post.userProfile));
      localStorage.setItem("refresh_token", post.tokenResource.refreshToken);
      localStorage.setItem("access_token", post.tokenResource.accessToken);
      setStep(2);
    } else {
      if (post?.tokenResource?.registrationToken) {
        window.history.replaceState(null, "", "/kayit");
        setRegistrationToken(post.tokenResource.registrationToken);
        setStep(4);
      } else {
        return post;
      }
    }
    return true;
  };

  const handleClickForgetPass = () => {
    window.history.replaceState(null, "", "/sifremiunuttum");
    setStep(3);
  };

  const handleSendAgainOtp = async (type: string) => {
    const body = {
      username: resultOfService.msisdn,
    };

    const responseResult = await sendLoginOtp(body).then((response) => {
      return response;
    });
    if (responseResult) {
      setResultOfService(responseResult);
    }
  };

  const onClickContinue = async (pass: string) => {
    const body = {
      pin: pass,
    };
    const result = await verifyPin(body).then((response) => {
      return response;
    });

    if (result?.walletToken) {
      localStorage.setItem("wallet_token", result.walletToken);
      const resultProfile = await getUserProfile().then((response) => {
        return response;
      });
      if (resultProfile) {
        await dispatch(setProfile(resultProfile));
        navigate("/profile");
      }
    }
  };

  switch (step) {
    case 0:
      return <Login onClickLogin={handleClickLogin} />;
    case 1:
      return (
        <OtpPages
          type="smsOtp"
          header={{
            title: t("smsOtp.headerTitle"),
            dsc:
              "+90 " +
              resultOfService.msisdn.slice(0, 3) +
              " " +
              resultOfService.msisdn.slice(3, 6) +
              " " +
              resultOfService.msisdn.slice(6, 8) +
              " " +
              resultOfService.msisdn.slice(8, 10) +
              " " +
              t("smsOtp.headerDesc"),
          }}
          resultOfService={resultOfService}
          handleSendAgainOtp={handleSendAgainOtp}
          buttonTertiary={{
            buttonTertiaryTitle: t("smsOtp.buttonTertiaryTitle"),
            buttonTertiaryPress: async (otpValue: string) => {
              return await handleClickSendOtp(otpValue);
            },
          }}
          onKeyDown={async (e: any, otpValue: any) => {
            if (e.key === "Enter") {
              return await handleClickSendOtp(otpValue);
            }
          }}
        />
      );
    case 2:
      return (
        <PasswordEnter
          header={{
            title: t("password.headerEnter.title"),
            desc: t("password.headerEnter.desc"),
          }}
          onClickContinue={onClickContinue}
          onClickForgetPass={handleClickForgetPass}
        />
      );
    case 3:
      return <PasswordForgetProcess />;
    case 4:
      return (
        <RegisterProcess
          msisdn={resultOfService.msisdn}
          registrationToken={registrationToken}
        />
      );
    default:
      break;
  }

  return <></>;
}

export default LoginProcess;
