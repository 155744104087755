import { Grid } from "@mui/material";
import { tss } from "tss-react/mui";
import NotificationIcon from "../../icons/notificationIcon.svg";
import { useTheme } from "@emotion/react";
import AccountMenu from "../AccountMenu/AccountMenu";
import UserIcon from "../../icons/userIcon.svg";
import Settings from "../../icons/settingsIcon.svg";
import DirectionIcon from "../../icons/directionIcon.svg";
import LangIcon from "../../icons/langIcon.svg";
import BackIcon from "../../icons/backIcon.svg";
import TicIcon from "../../icons/ticIcon.svg";
import NoneTicIcon from "../../icons/noneTicIcon.svg";
import RemoveUserIcon from "../../icons/removeUserIcon.svg";
import MenuLockIcon from "../../icons/menuLockIcon.svg";
import PermissionIcon from "../../icons/permissionIcon.svg";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppDownloadIcon from "../../icons/appDownloadIcon.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

type Props = {
  hideHeaderBack?: boolean;
  _handleBackAction?: Function;
};

const useStyles = tss
  .withParams<{
    customTheme: any;
  }>()
  .create(({ customTheme }) => ({
    root: {
      display: "flex",
      width: "100%",
      height: "72px",
      margin: "0 !important",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: "8px",
      padding:
        "var(--spacing-sm, 12px) var(--spacing-lg, 24px) var(--spacing-sm, 12px) 128px",
    },
    container: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      height: "48px",
      width: "100%",
    },
    image: {
      borderRadius: "50%",
      border: `1px solid ${customTheme.custom.color.outline.variant.value}`,
      alignSelf: "center",
      marginLeft: "24px",
    },
    notificationIcon: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
    },
    spacingLg: {
      display: "flex",
      gap: customTheme.custom.spacing.lg.value,
    },
    imageNotification: { height: "26px", alignSelf: "center", width: "26px" },
  }));

function LoginHeader(props: Props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const profile = useSelector((state: any) => state.profile);
  const { classes } = useStyles({
    customTheme: theme,
  });
  const { t, i18n } = useTranslation();
  let userObject = {
    userName: profile.name + " " + profile.surname,
    userPicture: profile.url ? profile.url : null,
  };
  let userObjectnull = {
    userName: "",
    userPicture: profile.url,
  };
  const [userInformation, setUserInformation] = useState(userObject);
  const [accountMenuHeader, setAccountMenuHeader] = useState({});
  let settingsArray = [
    {
      key: 0,
      icon: MenuLockIcon,
      title: t("modalMenu.passwordChange"),
      directionIcon: false,
      press: () => {
        navigate("/sifremiguncelle");
      },
    },
    {
      key: 1,
      icon: PermissionIcon,
      title: t("modalMenu.permission"),
      directionIcon: false,
      press: (item: any) => {},
    },
    {
      key: 2,
      icon: RemoveUserIcon,
      title: t("modalMenu.removeAccound"),
      directionIcon: false,
      press: (item: any) => {
        navigate(`/deleteAccount`, {
          state: {
            step: 0,
          },
        });
      },
    },
  ];

  let langArray = [
    {
      key: 0,
      icon: i18next.language == "tr" ? TicIcon : NoneTicIcon,
      title: t("modalMenu.tr"),
      directionIcon: false,
      press: (item: any) => {
        i18n.changeLanguage("tr");
      },
    },
    {
      key: 1,
      icon: i18next.language == "tr" ? NoneTicIcon : TicIcon,
      title: t("modalMenu.en"),
      directionIcon: false,
      press: (item: any) => {
        i18n.changeLanguage("en");
      },
    },
  ];

  let itemMenuArray = [
    {
      key: 0,
      icon: UserIcon,
      title: t("modalMenu.profileInformation"),
      directionIcon: false,
      press: (item: any) => {
        navigate("profile");
      },
    },
    {
      key: 1,
      icon: Settings,
      title: t("modalMenu.settings"),
      directionIcon: DirectionIcon,
      press: (item: any) => {
        setUserInformation(userObjectnull);
        setAccountMenuHeader({
          icon: BackIcon,
          title: t("modalMenu.settings"),
        });
        setMenuItem(settingsArray);
      },
    },
    {
      key: 2,
      icon: LangIcon,
      title: t("modalMenu.langEdit", {
        lang: i18next.language == "tr" ? "Türkçe" : "English",
      }),
      directionIcon: DirectionIcon,
      press: (item: any) => {
        setUserInformation(userObjectnull);
        setAccountMenuHeader({
          icon: BackIcon,
          title: t("modalMenu.langPreference"),
        });
        setMenuItem(langArray);
      },
    },
    {
      key: 3,
      icon: AppDownloadIcon,
      title: t("modalMenu.appDownload"),
      directionIcon: false,
      appDownload: true,
      press: (item: any) => {},
    },
  ];

  useEffect(() => {
    setUserInformation({
      userName: profile.name + " " + profile.surname,
      userPicture: profile.url ? profile.url : null,
    });
  }, [profile]);

  const handleBack = () => {
    props._handleBackAction && props._handleBackAction();
  };

  const goBack = () => {
    setUserInformation(userObject);
    setAccountMenuHeader({});
    setMenuItem(itemMenuArray);
  };

  const changeItem = async (item?: any) => {
    item.press(item);
  };

  const [menuItem, setMenuItem] = useState(itemMenuArray);

  return (
    <Grid className={classes.root}>
      <Grid className={classes.container}>
        <div className={classes.notificationIcon}>
          <img className={classes.imageNotification} src={NotificationIcon} />
        </div>
        <div className={classes.spacingLg} />
        <div className={classes.image}>
          <AccountMenu
            userInformation={userInformation}
            menuItem={menuItem}
            header={accountMenuHeader}
            onClick={changeItem}
            goBack={goBack}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default LoginHeader;
