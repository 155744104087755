import { ChangeEvent, useEffect, useState } from "react";
import { Box, Grid, InputAdornment } from "@mui/material";
import TextField from "../../components/TextField/TextField";
import { tss } from "tss-react/mui";
import Layout from "../../components/Layout/Layout";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "../../components/Button/Button";
import PasswordEnter from "../Password/PasswordEnter";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { sendUsersDelete } from "../../services/usersDelete";

type Props = {};

const useStyles = tss
  .withParams<{
    customTheme: any;
  }>()
  .create(({ customTheme }) => ({
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
    },
    button: {
      display: "flex",
      justifyContent: "center",
      paddingTop: customTheme.custom.spacing.lg.value,
    },
    removeAccountDesc1: {
      color: customTheme.custom.color.text.primary.value,
      fontSize: "16px",
      fontStyle: "normal",
      textAlign: "center",
      fontWeight: 400,
      lineHeight: "150%",
      paddingBottom: customTheme.custom.spacing.xs.value,
    },
    removeAccountDesc2: {
      color: customTheme.custom.color.text.secondary.value,
      textAlign: "center",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "150%",
    },
    mt24: {
      marginTop: 24,
    },
  }));

function DeleteAccountSucces(props: Props) {
  const theme: any = useTheme();
  const { classes, cx } = useStyles({
    customTheme: theme,
  });
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Layout
      hideHeaderBack
      bg
      propPadding={`${theme.custom.spacing.xl.value} ${theme.custom.spacing.lg.value}`}
      header={{
        title: t("deleteAccountSucces.layoutHeader.title"),
      }}
    >
      <Grid item xs={12} className={classes.removeAccountDesc1}>
        {t("deleteAccountSucces.deleteAccountDesc1")}
      </Grid>
      <Grid item xs={12} className={classes.removeAccountDesc2}></Grid>
      <Grid item xs={12} className={classes.buttonContainer}>
        <Grid item xs={8} className={classes.button}>
          <Button
            type="secondary"
            className={classes.mt24}
            onClick={() => {
              navigate(`/`);
            }}
          >
            {t("deleteAccountSucces.deleteAccountSuccesButtonText")}
          </Button>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default DeleteAccountSucces;
