import { useTheme } from "@emotion/react";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { tss } from "tss-react";
import Button from "../../../components/Button/Button";
import Layout from "../../../components/Layout/Layout";
import TextField from "../../../components/TextField/TextField";
import errorToast from "../../../components/Toast/ErrorToast";
import successToast from "../../../components/Toast/SuccessToast";
import { emailUpate } from "../../../services/emailServices";

const useStyles = tss
  .withParams<{
    customTheme: any;
  }>()
  .create(({ customTheme }) => ({
    textfieldContainer: {
      marginTop: customTheme.custom.spacing.lg.value,
      marginBottom: customTheme.custom.spacing.lg.value,
    },
    buttonContainer: {
      display: "flex",
    },
    buttonAdd: {
      padding: 16,
      paddingLeft: 64,
      paddingRight: 64,
    },
    buttonSpace: {
      width: customTheme.custom.spacing.md.value,
    },
    buttonCancel: {
      padding: 16,
      paddingLeft: 64,
      paddingRight: 64,
    },
  }));

export default function UpdateEmail() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const profile = useSelector((state: any) => state.profile);
  const [email, setEmail] = useState({ value: "", error: false });
  const { classes, cx } = useStyles({ customTheme: theme });

  useEffect(() => {
    setEmail({
      ...email,
      value: profile?.email,
      error: false,
    });
  }, [profile]);

  async function editEmail() {
    const result = await emailUpate({ email: email.value }).then(
      (result: any) => {
        return result;
      }
    );
    if (result?.status === 200) {
      navigate("/profile");
      successToast(t("email.edit.success"));
    }
  }

  return (
    <>
      <Layout
        header={{
          title: t("email.edit.headerTitle"),
        }}
        isLogin
        isBack
        _handleBackAction={() => {
          navigate("/profile");
        }}
      >
        <Grid item xs={12} className={classes.textfieldContainer}>
          <TextField
            onChange={(e) =>
              setEmail({
                ...email,
                value: e.target.value,
                error: false,
              })
            }
            value={email.value}
            header={t("email.edit.emailTitle")}
          />
        </Grid>
        <div className={classes.buttonContainer}>
          <Button
            onClick={() => navigate("/profile")}
            className={classes.buttonCancel}
            type="secondary"
          >
            {t("email.edit.button.cancel")}
          </Button>
          <div className={classes.buttonSpace} />
          <Button
            onClick={() => editEmail()}
            className={classes.buttonAdd}
            type="tertiary"
            disabled={email.value === profile.email ? true : false}
          >
            {t("email.edit.button.update")}
          </Button>
        </div>
      </Layout>
    </>
  );
}
