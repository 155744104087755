import { Grid } from "@mui/material";
import Layout from "../Layout/Layout";
import Button from "../Button/Button";
import { tss } from "tss-react/mui";

type Props = {
  title: string;
  desc: string;
  icon?: any;
  handleContinue?: Function;
  buttonText: string;
};

const useStyles = tss.create(({ theme }) => ({
  pt: {
    paddingTop: 8,
  },
}));

function Info(props: Props) {
  const { classes } = useStyles();

  return (
    <Layout
      header={{
        title: props.title,
        desc: props.desc,
        icon: props.icon,
      }}
    >
      <Grid item xs={12} className={classes.pt}>
        <Button
          type="tertiary"
          variant="contained"
          text={props.buttonText}
          onClick={props.handleContinue}
        />
      </Grid>
    </Layout>
  );
}

export default Info;
