import { Grid } from "@mui/material";
import { tss } from "tss-react/mui";
import Layout from "../../components/Layout/Layout";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import Stroke from "../../icons/stroke.svg";
import UserCircle from "../../icons/user-circle.svg";
import Edit from "../../icons/edit.svg";
import Frame from "../../icons/frame.svg";
import RightIcon from "../../icons/rightIcon.svg";
import SuccessIcon from "../../icons/success.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Modal from "../../components/Modal/Modal";
import Avatar from "../../icons/avatar.svg";
import Button from "../../components/Button/Button";
import EditGreenIcon from "../../icons/editGreenIcon.svg";
import DeleteGreenIcon from "../../icons/delete.svg";
import { useEffect, useState } from "react";
import ErrorIcon from "../../icons/errorIcon.svg";
import {
  deleteProfilePicture,
  getUserProfile,
  uploadProfilePicture,
} from "../../services/profileServices";
import { setProfile } from "../../redux/actions/profileActions";
import { useDispatch } from "react-redux";

type Props = {};

const useStyles = tss
  .withParams<{
    customTheme: any;
  }>()
  .create(({ customTheme }) => ({
    avatar: {
      paddingTop: customTheme.custom.spacing.lg.value,
      paddingBottom: customTheme.custom.spacing.lg.value,
      justifyContent: "center",
      display: "flex",
    },
    avatarContainer: {
      position: "relative",
      height: 64,
      width: 64,
    },
    userCircle: {
      position: "absolute",
      top: 12,
      left: 12,
    },
    frame: {
      position: "absolute",
      top: 40,
      left: 40,
    },
    edit: {
      position: "absolute",
      top: 44,
      left: 44,
      cursor: "pointer",
    },
    profileRect: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      border: `1px solid ${customTheme.custom.color.outline.default.value}`,
      borderRadius: customTheme.custom.radius.lg.value,
    },
    userInfo: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
      cursor: "pointer",

      ":hover": {
        backgroundColor: "#F5F5F5",
      },
    },
    notClickable: {
      cursor: "auto",
      ":hover": {
        backgroundColor: "transparent",
      },
    },
    borderTopStyle: {
      borderRadius: customTheme.custom.radius.lg.value,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    borderBottomStyle: {
      borderRadius: customTheme.custom.radius.lg.value,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    userContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      paddingLeft: customTheme.custom.spacing.lg.value,
      paddingTop: customTheme.custom.spacing.lg.value,
      paddingBottom: customTheme.custom.spacing.lg.value,
    },
    userInfoTitle: {
      color: customTheme.custom.color.text.primary.value,
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "150%",
    },
    userInfoDesc: {
      color: customTheme.custom.color.text.primary.value,
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "150%",
    },
    userInfoEmail: {
      color: customTheme.custom.color.text.disabled.value,
      fontSize: 16,
      fontStyle: "italic",
      fontWeight: 400,
      lineHeight: "150%",
    },
    withoutBorder: {
      border: "none",
    },
    imgClass: {
      width: 24,
      height: 24,
      paddingRight: customTheme.custom.spacing.lg.value,
    },
    modalRect: {
      padding: customTheme.custom.spacing.lg.value,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    modalDesc: {
      color: customTheme.custom.color.text.secondary.value,
      textAlign: "center",
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "150%",
      marginBottom: customTheme.custom.spacing.lg.value,
    },
    modalImg: {
      width: 160,
      height: 160,
      marginBottom: customTheme.custom.spacing.lg.value,
    },
    buttonAddPicture: {
      width: "calc(100% - 58px)",
    },
    inputImageUpload: {
      visibility: "hidden",
      position: "absolute",
    },
    helperTextContainer: {
      display: "flex",
      flexDirection: "row",
      verticalAlign: "center",
      marginTop: customTheme.custom.spacing.lg.value,
      marginBottom: customTheme.custom.spacing.md.value,
    },
    helperText: {
      color: customTheme.custom.color.text.error.value,
      marginLeft: customTheme.custom.spacing.xs.value,
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "150%",
    },
    selectedImage: {
      width: 160,
      height: 160,
      borderRadius: 240,
      marginBottom: 24,
    },
    selectedImageButtonWrap: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    ml24: {
      marginLeft: customTheme.custom.spacing.md.value,
    },
    userImage: {
      width: 64,
      height: 64,
      position: "absolute",
      left: 0,
      borderRadius: 240,
    },
    mobileRow: {
      display: "flex",
      flexDirection: "row",
    },
    mobileSuccesIcon: {
      marginLeft: 8,
      visibility: "visible",
    },
    w100: {
      width: "100%",
    },
    borderLine: {
      borderBottom: `1px solid ${customTheme.custom.color.outline.default.value}`,
      marginRight: customTheme.custom.spacing.lg.value,
      marginLeft: customTheme.custom.spacing.lg.value,
    },
  }));

function Profile(props: Props) {
  const theme: any = useTheme();
  const { classes, cx } = useStyles({
    customTheme: theme,
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openModalFromLocation = window.history?.state?.usr?.openModal;
  const profile = useSelector((state: any) => state.profile);
  const [openModal, setOpenModal] = useState(false);
  const [errorUploadImage, setErrorUploadImage] = useState(false);
  const [updateImage, setUpdateImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>([]);
  const [selectedImageFile, setSelectedImageFile] = useState<any>(undefined);

  useEffect(() => {
    openModalFromLocation && setOpenModal(true);
    window.history.replaceState({}, "");
  }, [openModalFromLocation]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      getProfile();
    };

    fetchUserProfile();
  }, []);

  const getProfile = async () => {
    const resultProfile = await getUserProfile().then((response) => {
      return response;
    });
    if (resultProfile) {
      await dispatch(setProfile(resultProfile));
    }
  };

  const handleUploadClick = async (event: any) => {
    var file = event.target.files[0];

    if (file.size > 2000000) {
      setErrorUploadImage(true);
    } else {
      setErrorUploadImage(false);
      const reader = new FileReader();
      var url = reader.readAsDataURL(file);

      reader.onloadend = async (e: any) => {
        setSelectedImage([reader.result]);
        setSelectedImageFile(event.target.files[0]);
      };
    }
  };

  const saveImage = async () => {
    const result = await uploadProfilePicture(selectedImageFile).then(
      (result: any) => {
        return result.data ? result.data : result;
      }
    );
    getProfile();
    setUpdateImage(false);
    setOpenModal(false);
    setSelectedImage([]);
  };

  const deleteImage = async () => {
    const result = await deleteProfilePicture().then((result: any) => {
      return result.data ? result.data : result;
    });
    getProfile();
    setUpdateImage(false);
    setOpenModal(false);
    setSelectedImage([]);
  };

  return (
    <>
      <Layout
        header={{
          title: t("profile.header"),
        }}
        propPadding={`${theme.custom.spacing.xl.value} ${theme.custom.spacing.lg.value} ${theme.custom.spacing.lg.value}`}
        layoutPadding={156}
        isLogin
      >
        <Grid item xs={12}>
          <div className={classes.avatar}>
            <div className={classes.avatarContainer}>
              <img src={Stroke} alt="stroke" />
              {profile.url ? (
                <img
                  className={classes.userImage}
                  src={profile.url}
                  alt="user-circle"
                />
              ) : (
                <img
                  className={classes.userCircle}
                  src={UserCircle}
                  alt="user-circle"
                />
              )}
              <img className={classes.frame} src={Frame} alt="frame" />
              <img
                className={classes.edit}
                src={Edit}
                alt="edit"
                onClick={() => {
                  setOpenModal(true);
                }}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.profileRect}>
            <div
              className={cx(classes.userInfo, classes.borderTopStyle)}
              onClick={() => {
                navigate("/profile/personal");
              }}
            >
              <div className={classes.userContainer}>
                <div className={classes.userInfoTitle}>Kişisel Bilgiler</div>
                <div className={classes.userInfoDesc}>
                  {profile.name + " " + profile.surname}
                </div>
              </div>
              <img
                src={RightIcon}
                className={classes.imgClass}
                alt="right-icon"
              />
            </div>
            <div className={classes.w100}>
              <div className={classes.borderLine} />
            </div>
            <div className={cx(classes.userInfo, classes.notClickable)}>
              <div className={classes.userContainer}>
                <div className={classes.userInfoTitle}>Telefon Numarası</div>
                <div className={classes.mobileRow}>
                  <div className={classes.userInfoDesc}>
                    {"+90 " + profile.msisdn}
                  </div>
                  <img
                    className={classes.mobileSuccesIcon}
                    src={SuccessIcon}
                    alt="success-icon"
                  />
                </div>
              </div>
            </div>
            <div className={classes.w100}>
              <div className={classes.borderLine} />
            </div>
            <div
              className={cx(
                classes.userInfo,
                classes.withoutBorder,
                classes.borderBottomStyle
              )}
              onClick={() => {
                navigate(profile.email ? "/email/guncelle" : "/email/ekle");
              }}
            >
              <div className={classes.userContainer}>
                <div className={classes.userInfoTitle}>E-posta Adresi</div>
                <div
                  className={
                    profile.email ? classes.userInfoDesc : classes.userInfoEmail
                  }
                >
                  {profile.email ? profile.email : "E-posta adresi ekle"}
                </div>
              </div>
              <img src={Edit} className={classes.imgClass} alt="edit-icon" />
            </div>
          </div>
        </Grid>
      </Layout>
      <Modal
        open={openModal}
        modalTitle={"Profil fotoğrafı ekle"}
        handleClose={() => {
          setUpdateImage(false);
          setOpenModal(false);
          setSelectedImage([]);
        }}
      >
        <Grid item xs={12}>
          <div className={classes.modalRect}>
            {selectedImage && selectedImage.length !== 0 ? (
              <>
                <img
                  src={selectedImage}
                  className={classes.selectedImage}
                  alt="profile-url"
                />
                <div className={classes.selectedImageButtonWrap}>
                  <Button
                    text={t("profile.imageCancel")}
                    type="secondary"
                    onClick={() => {
                      setSelectedImage([]);
                      setOpenModal(false);
                    }}
                  />
                  <Button
                    className={classes.ml24}
                    text={t("profile.imageSave")}
                    type="tertiary"
                    onClick={() => {
                      saveImage();
                      setOpenModal(false);
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                {profile.url ? (
                  <img
                    src={profile.url}
                    className={classes.selectedImage}
                    alt="profile-url"
                  />
                ) : (
                  <img src={Avatar} className={classes.modalImg} alt="avatar" />
                )}
                {errorUploadImage && (
                  <div className={classes.helperTextContainer}>
                    <img src={ErrorIcon} alt="err" />
                    <span className={classes.helperText}>
                      {t("profile.errorImageUpload")}
                    </span>
                  </div>
                )}
                {(profile.url === null || !profile.url || updateImage) && (
                  <div className={classes.modalDesc}>
                    {t("profile.imageUploadDesc")}
                  </div>
                )}
                {profile.url && !updateImage ? (
                  <div className={classes.selectedImageButtonWrap}>
                    <Button
                      text={t("profile.imageDelete")}
                      type="edit"
                      startIcon={
                        <img src={DeleteGreenIcon} alt="delete-icon" />
                      }
                      onClick={() => {
                        deleteImage();
                        setOpenModal(false);
                      }}
                    />
                    <Button
                      className={classes.ml24}
                      text={t("profile.imageUpdate")}
                      type="edit"
                      startIcon={<img src={EditGreenIcon} alt="edit-icon" />}
                      onClick={() => {
                        setUpdateImage(true);
                      }}
                    />
                  </div>
                ) : (
                  <div className={classes.buttonAddPicture}>
                    <Button
                      type="edit"
                      variant="contained"
                      startIcon={<img src={EditGreenIcon} alt="edit-icon" />}
                      text={t("profile.uploadImageButtonText")}
                      component="label"
                    >
                      <input
                        className={classes.inputImageUpload}
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={handleUploadClick}
                      />
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </Grid>
      </Modal>
    </>
  );
}

export default Profile;
