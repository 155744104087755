import { customApiRequest } from "../util/httpUtils";

const lang = localStorage.getItem("language");

export async function verifyLoginOtp(body: object) {
  const options = {
    method: "POST",
    url: "https://tabiat.ekomobil.com.tr/dbys/v1/auth/verify-login-otp",
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": lang,
    },
    data: body,
  };
  const result = await customApiRequest(options);
  return result;
}

export async function sendResetPinOtp(body: object) {
  const accessToken: string | null = localStorage.getItem("access_token");
  const options = {
    method: "POST",
    url: "https://tabiat.ekomobil.com.tr/dbys/v1/auth/send-reset-pin-otp",
    headers: {
      Authorization: accessToken,
      "Content-Type": "application/json",
      "Accept-Language": lang,
    },
    data: body,
  };
  const result = await customApiRequest(options);

  return result;
}

export async function verifyResetPinOtp(body: object) {
  const accessToken: string | null = localStorage.getItem("access_token");
  const options = {
    method: "POST",
    url: "https://tabiat.ekomobil.com.tr/dbys/v1/auth/verify-reset-pin-otp",
    headers: {
      Authorization: accessToken,
      "Content-Type": "application/json",
      "Accept-Language": lang,
    },
    data: body,
  };
  const result = await customApiRequest(options);

  return result;
}

export async function verifyEmailOtp(body: object) {
  const options = {
    method: "POST",
    url: "https://tabiat.ekomobil.com.tr/dbys/v1/auth/verify-email-otp",
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": lang,
    },
    data: body,
  };
  const result = await customApiRequest(options);
  return result;
}

export async function sendEmailOtp(body: object) {
  const options = {
    method: "POST",
    url: "https://tabiat.ekomobil.com.tr/dbys/v1/auth/send-email-otp",
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": lang,
    },
    data: body,
  };
  const result = await customApiRequest(options);
  return result;
}

export async function sendResetPinEmailOtp() {
  const accessToken: string | null = localStorage.getItem("access_token");
  const options = {
    method: "POST",
    url: "https://tabiat.ekomobil.com.tr/dbys/v1/auth/send-reset-pin-email-otp",
    headers: {
      Authorization: accessToken,
      "Content-Type": "application/json",
      "Accept-Language": lang,
    },
    data: {},
  };
  const result = await customApiRequest(options);

  return result;
}

export async function verifyResetPinEmailOtp(body: object) {
  const accessToken: string | null = localStorage.getItem("access_token");
  const options = {
    method: "POST",
    url: "https://tabiat.ekomobil.com.tr/dbys/v1/auth/verify-reset-pin-email-otp",
    headers: {
      Authorization: accessToken,
      "Content-Type": "application/json",
      "Accept-Language": lang,
    },
    data: body,
  };
  const result = await customApiRequest(options);

  return result;
}
