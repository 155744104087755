import { TextField as TextFieldMui } from "@mui/material";
import { ChangeEvent, ChangeEventHandler, KeyboardEventHandler } from "react";
import { tss } from "tss-react/mui";
import { isSafari } from "../../util/devUtils";
import ErrorIcon from "../../icons/errorIcon.svg";
import { useTheme } from "@emotion/react";

type Props = {
  id?: string;
  label?: string;
  placeHolder?: string;
  children?: any;
  disabled?: boolean;
  className?: any;
  onChange?:
    | ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
  defaultValue?: string;
  error?: boolean;
  helperText?: string;
  value?: any;
  type?: string;
  inputProps?: any;
  phoneNumberPrefix?: boolean;
  header?: string;
  inputProsValue?: any;
  autoFocus?: boolean;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
};

const useStyles = tss
  .withParams<{
    disabled: boolean | undefined;
    error: boolean | undefined;
    phoneNumberPrefix: boolean | undefined;
    inputProps: any;
    customTheme: any;
  }>()
  .create(
    ({
      theme,
      disabled,
      error,
      phoneNumberPrefix,
      inputProps,
      customTheme,
    }) => ({
      textField: {
        width: "100%",
        fontFamily: "Poppins",

        ".MuiOutlinedInput-root": {
          borderRadius: customTheme.custom.radius.md.value,
        },

        "& .MuiOutlinedInput-notchedOutline": {
          border: error ? "2px solid" : "1px solid",
          borderColor: error
            ? customTheme.custom.color.input.stroke.error.value
            : customTheme.custom.color.input.stroke.default.value,
        },

        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: disabled
            ? customTheme.custom.color.input.stroke.disabled.value +
              " !important"
            : error
            ? customTheme.custom.color.input.stroke.error.value + " !important"
            : customTheme.custom.color.input.stroke.hover.value + " !important",
          border: error ? "2px solid" : "1px solid",
        },
        "& .MuiOutlinedInput-input": !phoneNumberPrefix
          ? inputProps?.endAdornment
            ? { border: "none !important" }
            : {
                fontSize: 16,
              }
          : {
              fontWeight: "400",
              fontSize: 16,
              lineHeight: 1.5,
              letterSpacing: "0.00938em",
            },

        "& .MuiInputBase-input": !phoneNumberPrefix
          ? {
              padding: "12px",
              border: "1px solid",
              borderColor: customTheme.custom.color.input.stroke.default.value,
              borderRadius: customTheme.custom.radius.md.value,
            }
          : {
              border: "none",
              borderLeft: "1px solid",
              borderColor: customTheme.custom.color.input.stroke.default.value,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              padding: 0,
              margin: "10px 10px",
              paddingLeft: 10,
            },

        "& .Mui-error": {
          "& .MuiInputBase-input": {
            color: customTheme.custom.color.text.primary.value,
          },
        },

        "& .Mui-focused": {
          outline: isSafari() ? "none" : "#141414 auto 2px",

          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        },
        "& .MuiFormHelperText-root": {
          marginLeft: 0,
          outline: "none",
        },

        "input#outlined-basic": {
          opacity: disabled ? 0 : 1,
        },

        ".Mui-disabled": {
          border: "1px solid",
          borderColor: customTheme.custom.color.input.stroke.disabled.value,
          backgroundColor: "#F5F5F5",
          " .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        },
        ".MuiFormHelperText-root.Mui-error": {
          fontSize: 14,
          color: customTheme.custom.color.text.error.value,
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "150%",
          marginTop: customTheme.custom.spacing.xxs.value,
          fontFamily: "Poppins",
        },

        "input:-webkit-autofill": {
          WebkitBoxShadow: " 0 0 0 30px white inset !important",
          borderRadius: 0,
        },
      },
      inputTitle: {
        display: "flex",
        color: error
          ? customTheme.custom.color.text.error.value
          : customTheme.custom.color.text.primary.value,
        textAlign: "left",
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "150%",
        marginBottom: customTheme.custom.spacing.xxs.value,
      },
      helperTextContainer: {
        display: "flex",
        flexDirection: "row",
        verticalAlign: "center",
      },
      helperText: {
        marginLeft: customTheme.custom.spacing.xs.value,
        marginTop: customTheme.custom.spacing.xxs.value,
      },
    })
  );

function TextField(props: Props) {
  const {
    label,
    id,
    placeHolder,
    children,
    disabled,
    className,
    defaultValue,
    error,
    helperText,
    value,
    onChange,
    type,
    inputProps,
    phoneNumberPrefix,
    inputProsValue,
    header,
    autoFocus,
    onKeyDown,
  } = props;
  const theme = useTheme();
  const { classes, cx } = useStyles({
    disabled: disabled,
    error: error,
    phoneNumberPrefix: phoneNumberPrefix,
    inputProps: inputProps,
    customTheme: theme,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 200) {
      return;
    }
    onChange && onChange(event);
  };

  return (
    <>
      <span className={classes.inputTitle}>{header}</span>
      <TextFieldMui
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
        id={id}
        label={label}
        placeholder={placeHolder}
        className={cx(classes.textField, className)}
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={handleChange}
        error={error}
        helperText={
          helperText && (
            <div className={classes.helperTextContainer}>
              <img src={ErrorIcon} alt="err" />
              <span className={classes.helperText}>{helperText}</span>
            </div>
          )
        }
        value={value}
        type={type}
        InputProps={inputProps}
        sx={{
          "& .MuiInputBase-input": {
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
        inputProps={inputProsValue}
      >
        {children}
      </TextFieldMui>
    </>
  );
}

export default TextField;
