import {
  Grid,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
} from "@mui/material";
import { tss } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Security from "../../icons/security.svg";
import Layout from "../../components/Layout/Layout";
import TextField from "../../components/TextField/TextField";
import Button from "../../components/Button/Button";
import Select from "../../components/Select/Select";
import eyeSvg from "../../icons/eye.svg";
import eyeCrossedSvg from "../../icons/eye-crossed.svg";
import { ChangeEvent, useEffect, useState } from "react";
import { securityQuestions } from "../../services/securityQuestionsServices";

type Props = {
  onClickSecurityQuestions?: (id: string, answer: string) => {};
  forgetPasswordStep?: boolean;
  defaultQuestion?: any;
  defaultValue?: any;
  registrationToken?: string;
  onClickForgetAnswer?: () => {};
};

const useStyles = tss.create(({ theme }) => ({
  pb16: {
    paddingBottom: 16,
  },
  mb16: {
    marginBottom: 16,
  },
  buttonWrap: {
    display: "flex",
    justifyContent: "center",
  },
}));

function SecurityQuestions(props: Props) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [pass, setPass] = useState("");
  const [question, setQuestion] = useState("");
  const [questionList, setQuestionList] = useState([{ id: "", name: "" }]);
  const [errQuestion, setErrQuestion] = useState(false);

  useEffect(() => {
    const fetchQuestions = async () => {
      const body = {
        registrationToken: props.registrationToken,
      };
      const result = await securityQuestions(body).then((response) => {
        return response;
      });
      if (result && result.length > 0) {
        let newArr = [];
        for (let x = 0; x < result.length; x++) {
          let newObj = { id: result[x].id, name: result[x].question };
          newArr.push(newObj);
        }
        setQuestionList(newArr);
      }
    };

    !props.forgetPasswordStep && fetchQuestions();
  }, []);

  const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPass(event.target.value);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleChangeQuestion = (event: SelectChangeEvent<any>) => {
    setQuestion(event.target.value);
  };

  const handleClickContinue = () => {
    props.onClickSecurityQuestions &&
      props.onClickSecurityQuestions(question, pass);
  };

  const handleClickForgetAnswer = () => {
    props.onClickForgetAnswer && props.onClickForgetAnswer();
  };

  return (
    <Layout
      header={{
        title: "Güvenlik adımı ekleyin",
        desc: "Hesabınıza erişememeniz durumunda, hesabı kurtarabilmek için kullanacağınız güvenlik sorunuzu ve cevabınızı seçin.",
      }}
    >
      <Grid item xs={12}>
        <Select
          labelId="security-label"
          id="security"
          value={props.defaultValue ? props.defaultValue : question}
          onChange={handleChangeQuestion}
          error={errQuestion}
          errorText={errQuestion ? "" : ""}
          menuItemList={
            props.defaultQuestion ? props.defaultQuestion : questionList
          }
          header="Güvenlik Sorusu Seçiniz"
          inputClassName={
            question !== "" || props.forgetPasswordStep ? classes.pb16 : ""
          }
          labelText="Seçiniz"
          disabled={props.forgetPasswordStep}
        />
        {(question !== "" || props.forgetPasswordStep) && (
          <TextField
            id="outlined-basic"
            header="Cevabınız"
            className={classes.pb16}
            type={showPassword ? "text" : "password"}
            value={pass}
            onChange={handleChangePassword}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <img src={eyeSvg} alt="eye" />
                    ) : (
                      <img src={eyeCrossedSvg} alt="eye" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      </Grid>
      {(question !== "" || props.forgetPasswordStep) && (
        <Grid item xs={12} className={classes.buttonWrap}>
          <Button
            className={props.forgetPasswordStep ? classes.mb16 : ""}
            type="tertiary"
            variant="contained"
            text="Devam Et"
            onClick={handleClickContinue}
          />
        </Grid>
      )}
      {props.forgetPasswordStep && (
        <Grid item xs={12} className={classes.buttonWrap}>
          <Button
            type="text"
            variant="contained"
            text="Cevabı Unuttum"
            onClick={handleClickForgetAnswer}
          />
        </Grid>
      )}
    </Layout>
  );
}

export default SecurityQuestions;
