import { Grid } from "@mui/material";
import { tss } from "tss-react/mui";
import HeaderLogo from "../../icons/headerLogo.svg";
import HeaderBack from "../../icons/headerBack.svg";
import { useTheme } from "@emotion/react";

type Props = {
  hideHeaderBack?: boolean;
  _handleBackAction?: Function;
};

const useStyles = tss
.withParams<{
  customTheme: any;
}>()
.create(({ customTheme }) => ({
  container: {
    width: "100%",
    backgroundColor: customTheme.custom.color.surface.white.value,
    height: "72px",
    margin: "0 !important",
  },
  containerIcon: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    paddingTop: 16,
  },
  headerBack: {
    position: "absolute",
    left: customTheme.custom.spacing.lg.value,
    top: 16,
    cursor: "pointer",
  },
}));

function Header(props: Props) {
  const theme = useTheme();

  const { classes } = useStyles({
    customTheme: theme,
  });

  const handleBack = () => {
    props._handleBackAction && props._handleBackAction();
  };

  return (
    <Grid className={classes.container}>
      {!props.hideHeaderBack && (
        <Grid className={classes.headerBack}>
          <img src={HeaderBack} alt="Header Back" onClick={handleBack} />
        </Grid>
      )}
      <Grid className={classes.containerIcon}>
        <img src={HeaderLogo} alt="Header Icon" />
      </Grid>
    </Grid>
  );
}

export default Header;
