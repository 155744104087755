import axios from "axios";
import errorToast from "../components/Toast/ErrorToast";
import { refreshToken } from "../services/loginServices";

/**
 *  The part where API requests are sent
 *
 * @param options - The request options
 * @returns
 */

export const customApiRequest = async (options?: any) => {
  const result: any = await axios(options)
    .then(async (response: any) => {
      if (response && response.data) {
        return response.data;
      }
      return response;
    })
    .then((json) => {
      return json;
    })
    .catch(async (error) => {
      if (error.response.status === 401) {
        const res = await refreshToken().then((response) => {
          return response;
        });
        if (res?.accessToken && res?.refreshToken) {
          const accessToken: string | null =
            localStorage.getItem("access_token");
          if (options && options.headers) {
            options.headers.Authorization = accessToken;
          }
          return await customApiRequest(options);
        }
      } else {
        errorToast(error.response.data);
        return error.response.data;
      }
    });
  return result;
};
