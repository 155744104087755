import React from "react";
import { Tooltip as TooltipMui } from "@mui/material";
import { tss } from "tss-react";
import { useTheme } from "@emotion/react";

type Props = {
  children?: any;
  title: any;
  placement?: any;
};

const useStyles = tss
  .withParams<{
    customTheme: any;
  }>()
  .create(({ customTheme }) => ({
    arrow: {
      color: customTheme.custom.color.surface.black.value,
    },
    tooltip: {
      backgroundColor: customTheme.custom.color.surface.black.value,
      display: "flex",
      alignItems: "center",
      justifyItems: "center",
      textAlign: "center",
      color: customTheme.custom.color.text.white.value,
      fontFamily: "Poppins",
      fontSize: 14,
      fontWeight: 400,
      width: "100%",
      padding: 5,
    },
  }));

export default function Tooltip(props: Props) {
  const { children, title, placement } = props;
  const theme = useTheme();
  const { classes, cx } = useStyles({ customTheme: theme });
  return (
    <TooltipMui
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
      placement={placement}
      arrow
      title={title}
    >
      {children}
    </TooltipMui>
  );
}
