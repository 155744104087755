import React, { useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export default function Help() {
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    var lang = searchParams?.get("lang") ? searchParams?.get("lang") : "tr";
    i18n.changeLanguage(!!lang ? lang : "tr");
  }, []);

  return (
    <div style={styles.root}>
      <div style={styles.main}>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={styles.acordionSummary}
          >
            {t("help.question1.title")}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <div style={styles.accordionDetails}>
              <div>{t("help.question1.desc0")}</div>
              <div>{t("help.question1.desc1")}</div>
              <div>{t("help.question1.desc2")}</div>
              <div>{t("help.question1.desc3")}</div>
              <div>{t("help.question1.desc4")}</div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={styles.acordionSummary}
          >
            {t("help.question2.title")}
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <div style={styles.accordionDetails}>
              <div>{t("help.question2.desc0")}</div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

const styles = {
  root: {
    backgroundColor: "white",
    height: "100vh",
  },
  main: {
    padding: 22,
  },
  acordionSummary: {
    textAlign: "left" as "left",
    fontSize: 16,
    fontWeight: "500",
    borderWidth: 0,
    fontFamily: "Poppins",
    padding: 0,
  },
  accordionDetails: {
    textAlign: "left" as "left",
    fontSize: 16,
    fontWeight: 400,
    fontFamily: "Poppins",
    padding: 0,
  },
};
