import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { Grid } from "@mui/material";
import { tss } from "tss-react/mui";
import { useTheme } from "@emotion/react";
import NoProfilePicture from "../../icons/noProfilePicture.svg";
import { useSelector } from "react-redux";
import Edit from "../../icons/edit.svg";
import Frame from "../../icons/frame.svg";
import { useNavigate } from "react-router";

type Props = {
  menuItem: Array<any>;
  userInformation?: { userName: string; userPicture: any };
  header?: any;
  onClick?: (item?: any) => {};
  goBack?: Function;
};

const useStyles = tss
  .withParams<{
    customTheme: any;
  }>()
  .create(({ customTheme }) => ({
    image: {
      height: "64px",
      width: "64px",
      borderRadius: "50%",
      border: `1px solid ${customTheme.custom.color.outline.variant.value}`,
      alignSelf: "center",
      background: "white",
      overflow: "visible",
    },
    userNameText: {
      fontSize: "18px",
      fontWeight: "500",
      color: customTheme.custom.color.text.primary.value,
      marginTop: "8px",
    },
    increaseLimitText: {
      fontSize: "14px",
      fontWeight: "600",
      color: customTheme.custom.color.text.brand.value,
      marginTop: "8px",
      textDecorationLine: "underline",
    },
    menu: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignSelf: "stretch",
    },
    divider: {
      marginBottom: customTheme.custom.spacing.xs.value,
      marginTop: customTheme.custom.spacing.xs.value,
    },
    dummy: { marginTop: customTheme.custom.spacing.xs.value },

    menuItem: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
    },
    menuItemIcon: {
      flexDirection: "row",
      display: "flex",
      alignItems: "center",
    },
    menuItemTitle: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      marginLeft: "12px",
      fontStyle: "normal",
      fontSize: "14px",
      fontWeight: "400",
      color: customTheme.custom.color.text.primary.value,
      lineHeight: "21px",
      alignItems: "center",
    },
    menuItemHeaderTitle: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      marginLeft: "12px",
      fontStyle: "normal",
      fontSize: "16px",
      fontWeight: "500",
      color: customTheme.custom.color.text.primary.value,
      lineHeight: "24px",
      alignItems: "center",
    },
    menuItemEndIcon: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
    },
    avatarImage: {
      width: "100%",
      height: "100%",
      borderRadius: "50%",
    },
    bgWhite: {
      background: "white",
    },
    userInformationContainer: {
      flex: 1,
      display: "flex",
      width: "100%",
      height: "127px",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "24px",
      flexDirection: "column",
    },
    customMenu: {
      overflow: "visible",
      background: "#FFF",
      filter: "drop-shadow(0px 0px 8px rgba(0,0,0,0.24))",
      borderRadius: "16px",
      width: 328,
      mt: 1.5,
      marginTop: "7px",
    },
    userImage: {
      width: 40,
      height: 40,
    },
    userImage2: {
      width: 64,
      height: 64,
      borderRadius: 240,
    },
    frame: {
      position: "absolute",
      top: 40,
      left: 40,
    },
    edit: {
      position: "absolute",
      top: 44,
      left: 44,
      cursor: "pointer",
    },
  }));

export default function AccountMenu(props: Props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    props.goBack && props.goBack();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();

  const { classes } = useStyles({
    customTheme: theme,
  });

  const menuItemClick = (item: any) => {
    if (!item.directionIcon) {
      handleClose();
    }
    props.onClick && props.onClick(item);
  };

  const back = () => {
    props.goBack && props.goBack();
  };

  return (
    <React.Fragment>
      <Box>
        <Avatar onClick={handleClick} className={classes.bgWhite}>
          {props.userInformation?.userPicture ? (
            <img
              className={classes.userImage}
              src={props.userInformation.userPicture}
              alt="user-circle"
            />
          ) : (
            <img
              className={classes.avatarImage}
              alt="new"
              src={
                props.userInformation?.userPicture
                  ? props.userInformation?.userPicture
                  : NoProfilePicture
              }
            />
          )}
        </Avatar>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        classes={{
          paper: classes.customMenu,
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {props.userInformation?.userName && (
          <Grid>
            <Grid className={classes.userInformationContainer}>
              <Avatar className={classes.image}>
                {props.userInformation?.userPicture ? (
                  <img
                    className={classes.userImage2}
                    src={props.userInformation.userPicture}
                    alt="user-circle"
                  />
                ) : (
                  <img
                    className={classes.avatarImage}
                    alt="new"
                    src={
                      props.userInformation?.userPicture
                        ? props.userInformation?.userPicture
                        : NoProfilePicture
                    }
                  />
                )}
                <img className={classes.frame} src={Frame} alt="frame" />
                <img
                  className={classes.edit}
                  src={Edit}
                  alt="edit"
                  onClick={() => {
                    navigate("/profile", {
                      state: {
                        openModal: true,
                      },
                    });
                  }}
                />
              </Avatar>
              <text className={classes.userNameText}>
                {props.userInformation?.userName}
              </text>
              <text className={classes.increaseLimitText}>
                İşlem limitlerini artır
              </text>
            </Grid>
          </Grid>
        )}
        {props.userInformation?.userName && (
          <Divider className={classes.divider} />
        )}
        {props.header?.title && (
          <MenuItem onClick={() => back()}>
            <Grid className={classes.menuItem}>
              <Grid className={classes.menuItemIcon}>
                <img src={props.header.icon} />
              </Grid>
              <Grid className={classes.menuItemHeaderTitle}>
                {props.header.title}
              </Grid>
            </Grid>
          </MenuItem>
        )}
        {props.header?.title && <Divider />}

        {props.menuItem.map((item, index) => {
          return (
            <div className={classes.menu}>
              {item.appDownload && <Divider className={classes.divider} />}
              <MenuItem onClick={() => menuItemClick(item)} key={index}>
                <Grid className={classes.menuItem} key={index}>
                  <Grid className={classes.menuItemIcon}>
                    <img src={item.icon} />
                  </Grid>
                  <Grid className={classes.menuItemTitle}>{item.title}</Grid>
                  {item.directionIcon && (
                    <Grid className={classes.menuItemEndIcon}>
                      <img src={item.directionIcon} />
                    </Grid>
                  )}
                </Grid>
              </MenuItem>
            </div>
          );
        })}
        <div className={classes.dummy} />
      </Menu>
    </React.Fragment>
  );
}
