import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { tss } from "tss-react/mui";
import RightIcon from "../../icons/rightIcon.svg";
import LeftIcon from "../../icons/leftIcon.svg";

type Props = {
  steps: Array<any>;
};

const useStyles = tss.create(({ theme }) => ({
  slider: {
    alignItems: "center",
    justifyContent: "center",
    ".MuiStep-root.MuiStep-horizontal": {
      display: "none",
    },
    ".MuiStepConnector-root.MuiStepConnector-horizontal": {
      maxWidth: 56,
      "span.MuiStepConnector-line.MuiStepConnector-lineHorizontal": {
        width: 48,
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#A8A8A8",
        marginRight: 4,
        marginLeft: 4,
      },
    },
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "32px",
    justifyContent: "center",
  },
  arrow: {
    width: 40,
    height: 40,
    padding: 8,
    borderRadius: 100,
    backgroundColor: "#F5F5F5",
    cursor: "pointer",
  },
}));

export default function CustomSlider(props: Props) {
  const { classes } = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  useEffect(() => {
    const stepperLine: any = document.getElementsByClassName(
      "MuiStepConnector-line"
    );

    if (stepperLine && stepperLine.length > 0 && stepperLine[activeStep]) {
      for (let x = 0; x < stepperLine.length; x++) {
        stepperLine[x].style.borderColor = "#A8A8A8";
      }
      stepperLine[activeStep].style.borderColor = "black";
    }
  }, [activeStep]);

  const handleNext = () => {
    if (activeStep === props.steps.length - 2) {
      handleReset();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setActiveStep(3);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <React.Fragment>
        <div className={classes.container}>
          <img
            className={classes.arrow}
            src={LeftIcon}
            alt="left-right"
            onClick={() => {
              handleBack();
            }}
          />
          {props.steps[activeStep]}
          <img
            className={classes.arrow}
            src={RightIcon}
            alt="arrow-right"
            onClick={() => {
              handleNext();
            }}
          />
        </div>
      </React.Fragment>
      <Stepper className={classes.slider} activeStep={activeStep}>
        {props.steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={index} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
