import { Trans, useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import { tss } from "tss-react/mui";
import { MuiOtpInput } from "mui-one-time-password-input";
import { KeyboardEventHandler, useEffect, useState } from "react";
import OtpErrorIcon from "../../icons/otpError.svg";
import { useTheme } from "@emotion/react";

type Props = {
  type: string;
  handleChangeOtp?: Function;
  errorOtp?: boolean;
  verificationCodeErrorText?: string;
  handleSendAgainOtp?: Function;
  remainingSeconds?: any;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
};

const useStyles = tss
  .withParams<{
    errorOtpBorder?: boolean;
    customTheme: any;
  }>()
  .create(({ errorOtpBorder, customTheme }) => ({
    box: {
      flexGrow: 1,
      alignItems: "center",
    },
    root: {
      backgroundColor: "white",
    },
    otpInput: {
      padding: "16px 0px",
      "& .MuiOutlinedInput-input": {
        backgroundColor: customTheme.custom.color.input.surface.default.value,
        width: "90%",
        height: 20,
        border: `1px solid ${
          errorOtpBorder
            ? customTheme.custom.color.input.stroke.error.value
            : customTheme.custom.color.input.stroke.default.value
        }`,
        borderRadius: customTheme.custom.radius.md.value,
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          border: `1px solid ${customTheme.custom.color.input.stroke.focus.value}`,
          borderRadius: customTheme.custom.radius.md.value,
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    verificationCodeStyle: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      marginTop: -3,
      marginBottom: 16,
    },
    verificationCodeStyleText: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      color: customTheme.custom.color.text.error.value,
      fontSize: 14,
      fontWeight: "400",
      marginLeft: 4,
      wordWrap: "break-word",
    },
    countDownDsc: {
      textAlign: "center",
      color: customTheme.custom.color.text.secondary.value,
      fontSize: 14,
      fontWeight: "400",
      wordWrap: "break-word",
      marginTop: "20px",
      lineHeight: "21px",
    },
    countDownErrorDsc: {
      textAlign: "center",
      color: customTheme.custom.color.text.error.value,
      fontSize: 14,
      fontWeight: "400",
      wordWrap: "break-word",
      marginTop: 6,
    },
    countDownErrorDsc1: {
      alignItems: "center",
      display: "flex",
      flex: 1,
      justifyContent: "center",
    },
    countDownErrorButton: {
      textAlign: "center",
      color: customTheme.custom.color.text.brand.value,
      fontSize: 14,
      fontWeight: "400",
      wordWrap: "break-word",
      marginTop: 6,
      textDecorationLine: "underline",
      cursor: "pointer",
    },
    containerDescription2: {
      textAlign: "center",
      color: customTheme.custom.color.text.secondary.value,
      fontSize: 14,
      fontWeight: "400",
      wordWrap: "break-word",
      marginTop: 6,
    },
    text: {
      textAlign: "center",
      color: customTheme.custom.color.text.secondary.value,
      fontSize: 14,
      fontWeight: "600",
      wordWrap: "break-word",
    },
  }));

function EnterOTP(props: Props) {
  const [errorOtp, setErrorOtp] = useState(props.errorOtp);
  const theme = useTheme();
  const { classes } = useStyles({
    errorOtpBorder: errorOtp,
    customTheme: theme,
  });
  const [otp, setOtp] = useState("");
  const [currentMinutes, setMinutes] = useState("");
  const [currentSeconds, setSeconds] = useState("");
  const { t } = useTranslation();
  const [countDownError, setCountDownError] = useState(false);

  let timer: any;

  const handleChange = (newValue: string) => {
    props.handleChangeOtp && props.handleChangeOtp(newValue);
    setOtp(newValue);
  };

  const handleSendAgainOtp = () => {
    countDownActive();
    props.handleSendAgainOtp && props.handleSendAgainOtp();
  };
  useEffect(() => {
    if (countDownError) {
      setOtp("");
    }
  }, [countDownError]);

  useEffect(() => {
    setErrorOtp(props.errorOtp);
  }, [props.errorOtp]);

  useEffect(() => {
    countDownActive();
  }, []);

  function countDownActive() {
    setCountDownError(false);
    setErrorOtp(false);
    let minutes = Math.floor(props.remainingSeconds / 60);
    let extraSeconds = props.remainingSeconds % 60;
    let sny =
      extraSeconds.toString().length < 2
        ? `0${extraSeconds.toString()}`
        : extraSeconds.toString();
    setSeconds(sny);
    setMinutes(`0${minutes.toString()}`);
    countDown(function () {
      setCountDownError(true);
      setErrorOtp(true);
    });
  }

  function countDown(callback: Function) {
    let remainingSeconds = props.remainingSeconds
      ? props.remainingSeconds - 1
      : 179; // 3 minute, 180 Second
    timer = setInterval(function () {
      const minute = Math.floor(remainingSeconds / 60);
      const Second = remainingSeconds % 60;
      let sny =
        Second.toString().length < 2
          ? `0${Second.toString()}`
          : Second.toString();

      setSeconds(sny);
      setMinutes(`0${minute.toString()}`);

      if (remainingSeconds <= 0) {
        clearInterval(timer);
        callback();
      } else {
        remainingSeconds--;
      }
    }, 1000); // Her 1 saniyede bir çalışacak
  }
  return (
    <Box className={classes.box}>
      <Grid container className={classes.root}>
        <Grid item xs={12} md={12}>
          <MuiOtpInput
            autoFocus
            onKeyDown={props.onKeyDown}
            className={classes.otpInput}
            length={6}
            value={otp}
            onChange={handleChange}
            validateChar={(val: any) => !isNaN(val)}
          />
          {!!props.verificationCodeErrorText && (
            <Grid item xs={12} className={classes.verificationCodeStyle}>
              <img src={OtpErrorIcon} />
              <text className={classes.verificationCodeStyleText}>
                {props.verificationCodeErrorText}
              </text>
            </Grid>
          )}
          {countDownError ? (
            <>
              <Grid item xs={12} className={classes.countDownErrorDsc}>
                {t("otp.countDownErrorDsc")}
              </Grid>
              <Grid item xs={12} className={classes.countDownErrorDsc1}>
                <div
                  onClick={handleSendAgainOtp}
                  className={classes.countDownErrorButton}
                >
                  {t("otp.countDownResendButtonText")}
                </div>
              </Grid>
              {props.type == "emailOpt" && (
                <Grid item xs={12} className={classes.countDownDsc}>
                  {t("otp.otpEmailDesc")}
                </Grid>
              )}
            </>
          ) : (
            <Grid item xs={12} className={classes.containerDescription2}>
              <Trans
                i18nKey="otp.countDownDesc"
                components={{ bold: <text className={classes.text} /> }}
                values={{
                  currentMinutes: currentMinutes,
                  currentSeconds: currentSeconds,
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default EnterOTP;
