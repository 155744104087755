import { useTheme } from "@emotion/react";
import { Button as ButtonMui } from "@mui/material";
import { tss } from "tss-react/mui";
import { styled } from "@mui/material/styles";

type Props = {
  type?: "text" | "tertiary" | "outlined" | "secondary" | "edit" | undefined;
  variant?: "text" | "outlined" | "contained" | undefined;
  text?: string;
  children?: any;
  icon?: any;
  disabled?: boolean;
  className?: any;
  size?: "large" | "medium" | "small" | undefined;
  onClick?: any;
  startIcon?: any;
  component?: any;
};

const useStyles = tss
  .withParams<{
    customTheme: any;
  }>()
  .create(({ theme, customTheme }) => ({
    font: {
      padding: "16px",
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "100%",
      letterSpacing: "0.75",
      textTransform: "none",
      borderRadius: customTheme.custom.radius.full.value,
    },
    tertiary: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: customTheme.custom.spacing.xs.value,
      background: customTheme.custom.color.primary.brand.value,
      color: customTheme.custom.color.text.white.value,
      boxShadow: "none",
      "&.Mui-disabled": {
        background: customTheme.custom.color.action.disabled.value,
        color: customTheme.custom.color.text.disabled.value,
      },
      "&:hover": {
        boxShadow: "none",
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%), ${customTheme.custom.color.primary.brand.value}`,
      },
    },
    secondary: {
      width: "100%",
      color: customTheme.custom.color.text.brand.value,
      border: "1px solid",
      borderColor: customTheme.custom.color.primary.brand.value,
      background: customTheme.custom.color.surface.white.value,
      boxShadow: "none",
      "&.Mui-disabled": {
        background: customTheme.custom.color.action.disabled.value,
        color: customTheme.custom.color.text.disabled.value,
      },

      "&:hover": {
        boxShadow: "none",
        background: "rgba(0, 0, 0, 0.05)",
      },
    },
    text: {
      width: "100%",
      color: customTheme.custom.color.text.brand.value,
      border: "none",
      background: customTheme.custom.color.surface.white.value,
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
        background: customTheme.custom.color.surface.white.value,
      },
    },
    edit: {
      width: "100%",
      color: customTheme.custom.color.text.brand.value,
      border: "none",
      background: customTheme.custom.color.surface["brand-light"].value,
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
        background: customTheme.custom.color.surface["brand-light"].value,
      },
    },
  }));

function Button(props: Props) {
  const theme = useTheme();
  const {
    type,
    variant,
    text,
    children,
    icon,
    disabled,
    className,
    size,
    onClick,
    startIcon,
    component,
  } = props;
  const { classes, cx } = useStyles({ customTheme: theme });
  let classType = "";
  if (type === "tertiary") {
    classType = classes.tertiary;
  } else if (type === "secondary") {
    classType = classes.secondary;
  } else if (type === "text") {
    classType = classes.text;
  } else if (type === "edit") {
    classType = classes.edit;
  }

  return (
    <ButtonMui
      className={cx(classType, classes.font, className)}
      variant={variant}
      disabled={disabled}
      size={size}
      onClick={onClick}
      startIcon={startIcon}
      disableRipple
      component={component}
    >
      {text}
      {children}
      {icon}
    </ButtonMui>
  );
}

export default Button;
