import { customApiRequest } from "../util/httpUtils";

const lang = localStorage.getItem("language");

export async function sendUsersDelete(body: object) {
  const wallet_token: string | null = localStorage.getItem("wallet_token");
  const accessToken: string | null = localStorage.getItem("access_token");
  const options = {
    method: "DELETE",
    url: "https://tabiat.ekomobil.com.tr/dbys/v1/users/delete",
    headers: {
      Authorization: accessToken,
      "x-api-key": wallet_token,
      "Content-Type": "application/json",
      "Accept-Language": lang,
    },
    data: body,
  };
  const result = await customApiRequest(options);

  return result;
}
