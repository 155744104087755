import { Checkbox as CheckBoxMui, Grid } from "@mui/material";
import { ChangeEventHandler } from "react";
import { tss } from "tss-react/mui";
import ErrorIcon from "../../icons/errorIcon.svg";
import { useTheme } from "@emotion/react";

type Props = {
  checked?: boolean;
  name?: string;
  defaultChecked?: boolean;
  className?: any;
  onClick?: any;
  children?: any;
  error?: boolean;
  helperText?: string;
  onChange?:
    | ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
};

const useStyles = tss
  .withParams<{
    error: boolean | undefined;
    customTheme: any;
  }>()
  .create(({ theme, error, customTheme }) => ({
    checkBox: {
      color: error
        ? customTheme.custom.color.input.stroke.error.value
        : customTheme.custom.color.input.stroke.default.value,
      borderWidth: 0.5,
      borderRadius: 28,
      padding: 0,
      "& .MuiCheckbox-root": {
        color: customTheme.custom.color.input.stroke.brand.value,
        padding: 0,
      },
      "&.Mui-checked": {
        color: customTheme.custom.color.input.stroke.brand.value + "!important",
      },
      "& .MuiFormControlLabel-label": {
        color: "#3D2000",
        fontSize: 5,
        fontFamily: "Inter",
        fontWeight: 600,
        fontStyle: "normal",
      },
    },
    checKvkk: {
      flex: 1,
      width: "100%",
      marginTop: "8px",
      marginBottom: "8px",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
    },
    info: {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "18px",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      textAlign: "left",
      marginBottom: 4,
      span: {
        display: "flex",
        alignItems: "center",
        textAlign: "left",
      },
    },
    helperTextContainer: {
      marginLeft: 34,
      display: "flex",
      flexDirection: "row",
      verticalAlign: "center",
      color: customTheme.custom.color.input.stroke.error.value,
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 14,
      marginTop: 4,
      marginBottom: 8,
    },
    helperText: {
      marginLeft: 8,
      marginTop: 2,
    },
  }));

function CheckBox(props: Props) {
  const {
    checked,
    onChange,
    name,
    defaultChecked,
    className,
    onClick,
    children,
    helperText,
    error,
  } = props;
  const theme = useTheme();
  const { classes, cx } = useStyles({ error: error, customTheme: theme });

  return (
    <Grid className={classes.checKvkk} item>
      <Grid className={classes.info}>
        <CheckBoxMui
          onClick={onClick}
          className={cx(classes.checkBox, className)}
          checked={checked}
          onChange={onChange}
          name={name}
          defaultChecked={defaultChecked}
        />
        {children}
      </Grid>
      {error && (
        <div>
          {helperText && (
            <div className={classes.helperTextContainer}>
              <img src={ErrorIcon} alt="err" />
              <span className={classes.helperText}>{helperText}</span>
            </div>
          )}
        </div>
      )}
    </Grid>
  );
}

export default CheckBox;
