import { customApiRequest } from "../util/httpUtils";

const dbysAuthUrl = "https://tabiat.ekomobil.com.tr/dbys/v1/auth/";
const lang = localStorage.getItem("language");

export async function securityQuestions(body: object) {
  // Get Security Questions
  const options = {
    method: "POST",
    url: "https://tabiat.ekomobil.com.tr/dbys/v1/auth/security-questions",
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": lang,
    },
    data: body,
  };
  const result = await customApiRequest(options);

  return result;
}

export async function securityQuestion(body: object) {
  // Create Security Question
  const options = {
    method: "POST",
    url: "https://tabiat.ekomobil.com.tr/dbys/v1/auth/security-question",
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": lang,
    },
    data: body,
  };
  const result = await customApiRequest(options);

  return result;
}

export async function userSecurityQuestion() {
  // Get User Security Question
  const accessToken: string | null = localStorage.getItem("access_token");
  const options = {
    method: "GET",
    url: "https://tabiat.ekomobil.com.tr/dbys/v1/auth/user-security-question",
    headers: {
      Authorization: accessToken,
      "Content-Type": "application/json",
      "Accept-Language": lang,
    },
  };
  const result = await customApiRequest(options);

  return result;
}

export async function verifySecurityQuestion(body: object) {
  // Create Security Question
  const accessToken: string | null = localStorage.getItem("access_token");
  const options = {
    method: "POST",
    url: "https://tabiat.ekomobil.com.tr/dbys/v1/auth/verify-security-question",
    headers: {
      Authorization: accessToken,
      "Content-Type": "application/json",
      "Accept-Language": lang,
    },
    data: body,
  };
  const result = await customApiRequest(options);
  return result;
}
