import { Grid, IconButton, InputAdornment } from "@mui/material";
import { tss } from "tss-react/mui";
import { ChangeEvent, useState } from "react";
import { NUMBER_CASE } from "../../constants/regex";
import { PASSWORD_LENGTH } from "../../constants/variables";
import eyeSvg from "../../icons/eye.svg";
import eyeCrossedSvg from "../../icons/eye-crossed.svg";
import TextField from "../../components/TextField/TextField";
import Button from "../../components/Button/Button";
import Layout from "../../components/Layout/Layout";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";

type Props = {
  onClickForgetPass?: any;
  header: { title: string; desc: string };
  onClickContinue: any;
  isLogin?: boolean;
};

const useStyles = tss
  .withParams<{
    customTheme: any;
  }>()
  .create(({ customTheme }) => ({
    textInputField: {
      paddingBottom: customTheme.custom.spacing.lg.value,
    },
    buttonWrap: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    loginButton: {
      marginBottom: customTheme.custom.spacing.md.value,
    },
  }));

function PasswordEnter(props: Props) {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const { classes } = useStyles({
    customTheme: theme,
  });
  const [pass, setPass] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    if (
      NUMBER_CASE.test(event.target.value) &&
      event.target.value.length <= PASSWORD_LENGTH
    ) {
      setPass(event.target.value);
    }
  };

  const handleContinueClick = async () => {
    props.onClickContinue && props.onClickContinue(pass);
    // TO DO result has walletToken login SUCCESS
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handlePassForget = () => {
    props.onClickForgetPass && props.onClickForgetPass();
  };

  function handleKeyPress(event: any) {
    if (event.key === "Enter") {
      handleContinueClick();
    }
  }

  return (
    <Layout
      header={{
        title: props.header.title,
        desc: props.header.desc,
      }}
      isLogin={props.isLogin}
    >
      <Grid item xs={12}>
        <TextField
          autoFocus={true}
          id="outlined-basic"
          header={t("password.input.title")}
          className={classes.textInputField}
          type={showPassword ? "text" : "password"}
          value={pass}
          onChange={handleChangePassword}
          onKeyDown={handleKeyPress}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <img src={eyeSvg} alt="eye" />
                  ) : (
                    <img src={eyeCrossedSvg} alt="eye" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.buttonWrap}>
        <Button
          type="tertiary"
          variant="contained"
          text={t("password.passwordButtonText")}
          onClick={handleContinueClick}
          className={classes.loginButton}
        />
        {props.onClickForgetPass && (
          <Button
            type="text"
            variant="contained"
            onClick={handlePassForget}
            text={t("password.forgetPasswordText")}
          />
        )}
      </Grid>
    </Layout>
  );
}

export default PasswordEnter;
