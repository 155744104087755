import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import { tss } from "tss-react/mui";
import { useEffect } from "react";
import Help from "./pages/Mobile/Help/Help";
import FAQ from "./pages/Mobile/FAQ/FAQ";
import Fess from "./pages/Mobile/Fess/Fess";
import LoginProcess from "./pages/Login/LoginProcess";
import { ThemeProvider } from "@mui/material";
import { theme } from "./thema/theme";
import PasswordChange from "./pages/Password/PasswordChange";
import AddEmail from "./pages/Email/AddEmail/AddEmail";
import Profile from "./pages/Profile/Profile";
import PersonalInformation from "./pages/Profile/PersonalInformation";
import DeleteAccount from "./pages/DeleteAccount/DeleteAccount";
import UpdateEmail from "./pages/Email/UpdateEmail/UpdateEmail";
import Sidebar from "./components/Layout/Sidebar/Sidebar";
import DeleteAccountSucces from "./pages/DeleteAccount/DeleteAccountSucces";
import AccountTransactions from "./pages/AccountTransactions/AccountTransactions";
import SendMoney from "./pages/SendMoney/SendMoney";

const useStyles = tss.create(({ theme }) => ({
  toast: {
    "Toastify__toast--warning::before": {
      backgroundColor: "red",
    },
  },
}));

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const { classes } = useStyles();
  if (localStorage.getItem("language") === null) {
    localStorage.setItem("language", "tr");
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ToastContainer theme="colored" className={classes.toast} />
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<LoginProcess />} />
            <Route path="/" element={<Sidebar />}>
              <Route path="/profile" element={<Profile />} />
              <Route path="/email/ekle" element={<AddEmail />} />
              <Route path="/email/guncelle" element={<UpdateEmail />} />

              <Route path="/deleteAccount" element={<DeleteAccount />} />
              <Route
                path="/profile/personal"
                element={<PersonalInformation />}
              />
              <Route path="/sifremiguncelle" element={<PasswordChange />} />
              <Route path="/paragonder" element={<SendMoney />} />
              <Route
                path="/account/transactions"
                element={<AccountTransactions />}
              />
            </Route>
            <Route path="/sifremiunuttum" element={<LoginProcess />} />
            <Route path="/kayit" element={<LoginProcess />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/mobile/help" element={<Help />} />
            <Route path="/mobile/faq" element={<FAQ />} />
            <Route path="/mobile/fees" element={<Fess />} />
            <Route
              path="/deleteAccountSucces"
              element={<DeleteAccountSucces />}
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
