export const passwordCheckAvailability = (text: any) => {
  const arr = text.split("");
  let arrLength = arr.length;
  let k = 0;
  let counter1 = 0;
  let counter2 = 0;
  for (let i = 0; i < arrLength; i++) {
    if (i !== 0) {
      k = arr[i - 1];
    }
    let diff = arr[i] - k;
    if (i !== 0) {
      if (diff === 0) {
        counter1 = counter1 + 1;
        counter2 = 0;
        if (counter1 === 3) {
          return false;
        }
      } else if (diff === 1) {
        counter2 = counter2 + 1;
        counter1 = 0;
        if (counter2 === 3) {
          return false;
        }
      } else {
        counter1 = 0;
        counter2 = 0;
      }
    }
  }
  if (counter1 > 2 || counter2 > 2) {
    return false;
  } else return true;
};
