export function devMode() {
  if (process.env.NODE_ENV !== 'production') {
    return true;
  }
  return false;
}

export function isSafari() {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('safari') != -1) {
    if (ua.indexOf('chrome') === -1) {
      return true;
    }
  }
  return false;
}
