import { Grid, Popover } from "@mui/material";
import { tss } from "tss-react/mui";
import Layout from "../../components/Layout/Layout";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useEffect, useState } from "react";
import { Dropdown } from "@mui/base/Dropdown";
import { Menu } from "@mui/base/Menu";
import { MenuButton } from "@mui/base/MenuButton";
import { MenuItem } from "@mui/base/MenuItem";
import DownArrow from "../../icons/downArrow.svg";
import Decont from "../../icons/decont.svg";
import Button from "../../components/Button/Button";
import { getTransactions } from "../../services/transactionsServices";

// TO DO Deneme Obj silinicek
const transaction: any = {
  transactions: [
    {
      date: 1720700087900,
      transactionId: "5df6d63b-e561-423c-b535-8b097c0869b6",
      transactionType: "2",
      title: "Para transferi",
      subTitle: "ÇAĞRI KARAHAN",
      amount: 125.0,
      refundable: false,
      hasReceipt: true,
    },
    {
      date: 1720700094800,
      transactionId: "bdc95b5d-2a3f-46d0-bf3f-ea2db768eb21",
      transactionType: "1",
      title: "Depozito iadesi",
      subTitle: "Mustafa Kemal, Çankaya, Ankara",
      amount: 101.0,
      refundable: false,
      hasReceipt: true,
    },
    {
      date: 1720700041300,
      transactionId: "5a332e52-88b3-40ad-b0f6-82b37f27ac08",
      transactionType: "4",
      title: "ATM'den para çekme",
      subTitle: "",
      amount: 120.0,
      refundable: false,
      hasReceipt: true,
    },
    {
      date: 1720700007200,
      transactionId: "8df38119-4cdc-4781-8341-328b2f356e81",
      transactionType: "1",
      title: "Depozito iadesi",
      subTitle: "Mustafa Kemal, Çankaya, Ankara",
      amount: 101.0,
      refundable: false,
      hasReceipt: true,
    },
    {
      date: 1720699991300,
      transactionId: "9257bb8e-a23f-4991-97b1-6b16ba986cc1",
      transactionType: "1",
      title: "Depozito iadesi",
      subTitle: "Mustafa Kemal, Çankaya, Ankara",
      amount: 101.0,
      refundable: false,
      hasReceipt: true,
    },
    {
      date: 1720699988500,
      transactionId: "8367b6b2-7cb1-435e-8d91-fba936ac9d88",
      transactionType: "1",
      title: "Depozito iadesi",
      subTitle: "Mustafa Kemal, Çankaya, Ankara",
      amount: 101.0,
      refundable: false,
      hasReceipt: true,
    },
    {
      date: 1720699978800,
      transactionId: "576a06e9-de85-4898-b61a-a80b9af7fc22",
      transactionType: "1",
      title: "Depozito iadesi",
      subTitle: "Mustafa Kemal, Çankaya, Ankara",
      amount: 101.0,
      refundable: false,
      hasReceipt: true,
    },
    {
      date: 1720699970300,
      transactionId: "f995e2b7-812e-4265-a811-70286872703a",
      transactionType: "1",
      title: "Depozito iadesi",
      subTitle: "Mustafa Kemal, Çankaya, Ankara",
      amount: 101.0,
      refundable: false,
      hasReceipt: true,
    },
    {
      date: 1720699815400,
      transactionId: "abdf589c-a9e3-4846-ad08-10a2547bb07e",
      transactionType: "1",
      title: "Depozito iadesi",
      subTitle: "Mustafa Kemal, Çankaya, Ankara",
      amount: 101.0,
      refundable: false,
      hasReceipt: true,
    },
    {
      date: 1720699810800,
      transactionId: "22315540-8f42-4c62-a980-26f3c079a0bf",
      transactionType: "1",
      title: "Depozito iadesi",
      subTitle: "Mustafa Kemal, Çankaya, Ankara",
      amount: 101.0,
      refundable: false,
      hasReceipt: true,
    },
    {
      date: 1720699711800,
      transactionId: "dab8e30f-aca0-48d6-bbcf-62b02468de2c",
      transactionType: "1",
      title: "Depozito iadesi",
      subTitle: "Mustafa Kemal, Çankaya, Ankara",
      amount: 159.0,
      refundable: false,
      hasReceipt: true,
    },
  ],
  totalElements: 11,
  pageNumber: 0,
  pageSize: 30,
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  classes?: any;
}

const useStyles = tss
  .withParams<{
    customTheme: any;
  }>()
  .create(({ customTheme }) => ({
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      textAlign: "left",

      ".MuiTabs-indicator": {
        height: 4,
        backgroundColor: customTheme.custom.color.primary["brand-dark"].value,
      },

      ".Mui-selected": {
        color: customTheme.custom.color.text.primary.value + " !important",
      },
      ".MuiTab-root": {
        padding: `${customTheme.custom.spacing.md.value} ${customTheme.custom.spacing.sm.value} `,
        textTransform: "capitalize",
        fontSize: 16,
        color: customTheme.custom.color.text.secondary.value,
        marginBottom: 4,
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "150%",
      },

      "button#simple-tab-2": {
        display: "flex",
        flexDirection: "row",
        img: {
          width: 24,
          height: 24,
        },
      },
    },
    header: {
      paddingTop: 80,
      paddingLeft: "13.3565%",
      paddingRight: "13.3565%",
      marginBottom: -24,
      color: customTheme.custom.color.text.primary.value,
      fontSize: 32,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "130%",
      letterSpacing: "-0.64px",
    },
    subContainer: {
      paddingTop: customTheme.custom.spacing.lg.value,
      display: "flex",
      flexDirection: "row",
      ".MuiMenuButton-root": {
        borderRadius: customTheme.custom.radius.md.value,
        border: `1px solid ${customTheme.custom.color.input.stroke.default.value} `,
        background: customTheme.custom.color.input.surface.default.value,
        padding: `${customTheme.custom.spacing.xxs.value} ${customTheme.custom.spacing.xxs.value} ${customTheme.custom.spacing.xxs.value} ${customTheme.custom.spacing.md.value} `,
        display: "flex",
        alignItems: "center",
        color: customTheme.custom.color.text.primary.value,
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
      },
    },
    topIndicators: {
      position: "absolute",
      width: "100%",
      borderTop: `1px solid ${customTheme.custom.color.input.stroke.default.value} `,
      left: 0,
    },
    customTabPanel: {},
    pl16: {
      paddingLeft: customTheme.custom.spacing.md.value,
    },
    transactionHeader: {
      paddingTop: customTheme.custom.spacing.lg.value,
      color: customTheme.custom.color.text.secondary.value,
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "150%",
      letterSpacing: "0.24px",
      paddingBottom: customTheme.custom.spacing.xs.value,
    },
    transactionRect: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      borderRadius: 16,
      border: `1px solid ${customTheme.custom.color.outline.default.value} `,
    },
    transaction: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
    },
    transactionInfo: {
      display: "flex",
      flexDirection: "column",
      padding: `${customTheme.custom.spacing.md.value} ${customTheme.custom.spacing.lg.value} `,
    },
    transactionInfoTitle: {
      color: customTheme.custom.color.text.primary.value,
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "150%",
      letterSpacing: "0.24px",
    },
    transactionInfoSubTitle: {
      color: customTheme.custom.color.text.primary.value,
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "150%",
    },
    transactionInfoDesc: {
      color: customTheme.custom.color.text.secondary.value,
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "150%",
    },
    transactionAmount: {
      color: customTheme.custom.color.text.primary.value,
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "150%",
      paddingRight: customTheme.custom.spacing.md.value,
    },
    transactionAmountPositive: {
      color: customTheme.custom.color.text.brand.value,
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "150%",
      paddingRight: customTheme.custom.spacing.md.value,
    },
    transactionInfoRight: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingRight: customTheme.custom.spacing.lg.value,
    },
    decontImg: {
      width: 24,
      height: 24,
    },
    w100: {
      width: "100%",
    },
    borderLine: {
      borderBottom: `1px solid ${customTheme.custom.color.outline.default.value}`,
    },
    buttonStyle: {
      paddingTop: customTheme.custom.spacing.lg.value,
      paddingLeft: "31.189%",
      paddingRight: "31.189%",
    },
    popover: {
      ".MuiPaper-root": {
        borderRadius: customTheme.custom.radius.md.value,
      },
      ".MuiMenu-listbox": {
        listStyleType: "none",
        paddingLeft: customTheme.custom.spacing.md.value,
        paddingRight: customTheme.custom.spacing.md.value,
        marginTop: customTheme.custom.spacing.xs.value,
        marginBottom: customTheme.custom.spacing.xs.value,

        li: {
          padding: `${customTheme.custom.spacing.xs.value} 0px`,
          color: customTheme.custom.color.text.primary.value,
          fontSize: 16,
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "150%",
          fontFamily: "Poppins",
        },
      },
    },
  }));

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={props.classes?.customTabPanel}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function AccountTransactions() {
  const theme: any = useTheme();
  const { classes, cx } = useStyles({
    customTheme: theme,
  });
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [otherFilter, setOtherFilter] = useState("Diğer filtreler");
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const fetchUserTransaction = async () => {
      getUserTransactions();
    };

    fetchUserTransaction();
  }, []);

  const getUserTransactions = async () => {
    // const resultProfile = await getTransactions().then((response) => {
    //   return response;
    // });
    // debugger;
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const createHandleMenuClick = (menuItem: string) => {
    return () => {
      console.log(`Clicked on ${menuItem}`);
    };
  };

  const getTransactionField = (element: any, index: number) => {
    // var options = {
    //   weekday: "long",
    //   year: "numeric",
    //   month: "long",
    //   day: "numeric",
    // };
    var d: any = new Date(element.date);
    // d = d.toLocaleDateString("tr-TR", options);
    return (
      <div className={classes.transaction}>
        <div className={classes.transactionInfo}>
          <div className={classes.transactionInfoTitle}>
            {d.toLocaleString()}
          </div>
          <div className={classes.transactionInfoSubTitle}>{element.title}</div>
          <div className={classes.transactionInfoDesc}>{element.subTitle}</div>
        </div>
        <div className={classes.transactionInfoRight}>
          <div
            className={
              element.transactionType === "1"
                ? classes.transactionAmountPositive
                : classes.transactionAmount
            }
          >
            {element.transactionType === "1"
              ? "+" + parseFloat(element.amount) + ",00 TL"
              : parseFloat(element.amount) + ",00 TL"}
          </div>
          <img className={classes.decontImg} src={Decont} alt="decont" />
        </div>
      </div>
    );
  };

  const getBorderLine = () => {
    return (
      <div className={classes.w100}>
        <div className={classes.borderLine} />
      </div>
    );
  };

  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>{t("accountTransactions.header")}</div>
      <Layout
        propPadding={`${theme.custom.spacing.md.value} ${theme.custom.spacing.lg.value} ${theme.custom.spacing.lg.value}`}
        layoutPadding={156} //'9.5897%'
        isLogin
      >
        <Grid item xs={12}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label={t("accountTransactions.tab1")} {...a11yProps(0)} />
            <Tab label={t("accountTransactions.tab2")} {...a11yProps(1)} />
            <Tab
              label="Diğer filtreler"
              icon={<img src={DownArrow} alt="down-arrow" />}
              iconPosition="end"
              onClick={(e) => {
                handleClick(e);
                setValue(2);
              }}
              {...a11yProps(2)}
            />
          </Tabs>
          <Popover
            className={classes.popover}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Menu>
              <MenuItem onClick={() => setOtherFilter("Three")}>
                Para Transferleri
              </MenuItem>
              <MenuItem onClick={() => setOtherFilter("Four")}>
                Bakiye Yukleme
              </MenuItem>
              <MenuItem onClick={() => setOtherFilter("Five")}>
                Odeme Islemleri
              </MenuItem>
              <MenuItem onClick={() => setOtherFilter("Six")}>
                Odeme Iade/Iptal Islemleri
              </MenuItem>
            </Menu>
          </Popover>
        </Grid>
        {/* <Grid item xs={12}>
          <div className={classes.topIndicators}></div>
          <div className={classes.subContainer}>
            <Dropdown>
              <MenuButton>
                Tum Islemler
                <img src={DownArrow} alt="down-arrow" />
              </MenuButton>
              <Menu>
                <MenuItem onClick={createHandleMenuClick("Profile")}>
                  Profile
                </MenuItem>
                <MenuItem onClick={createHandleMenuClick("Language settings")}>
                  Language settings
                </MenuItem>
                <MenuItem onClick={createHandleMenuClick("Log out")}>
                  Log out
                </MenuItem>
              </Menu>
            </Dropdown>
            <div className={classes.pl16}>
              <Dropdown>
                <MenuButton>
                  Tum Zamanlar
                  <img src={DownArrow} alt="down-arrow" />
                </MenuButton>
                <Menu>
                  <MenuItem onClick={createHandleMenuClick("Profile")}>
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={createHandleMenuClick("Language settings")}
                  >
                    Language settings
                  </MenuItem>
                  <MenuItem onClick={createHandleMenuClick("Log out")}>
                    Log out
                  </MenuItem>
                </Menu>
              </Dropdown>
            </div>
          </div>
        </Grid> */}
        <Grid item xs={12}>
          <CustomTabPanel value={value} index={0}>
            <div className={classes.transactionHeader}>Temmuz 2024</div>
            <div className={classes.transactionRect}>
              {transaction.transactions.map((element: any, index: number) => {
                return (
                  <>
                    {getTransactionField(element, index)}
                    {index !== transaction.transactions.length - 1 &&
                      getBorderLine()}
                  </>
                );
              })}
            </div>
            {/* <div className={classes.transactionHeader}>Haziran 2024</div>
            <div className={classes.transactionRect}>
              {getTransactionField()}
            </div> */}
            <div className={classes.buttonStyle}>
              <Button
                text={t("accountTransactions.button")}
                type="secondary"
                onClick={() => {}}
              />
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            Item Two
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            {otherFilter}
          </CustomTabPanel>
        </Grid>
      </Layout>
    </div>
  );
}

export default AccountTransactions;
