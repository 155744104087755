import { Grid, IconButton, InputAdornment } from "@mui/material";
import { tss } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { ChangeEvent, useEffect, useState } from "react";
import { NUMBER_CASE } from "../../constants/regex";
import { PASSWORD_LENGTH } from "../../constants/variables";
import eyeSvg from "../../icons/eye.svg";
import eyeCrossedSvg from "../../icons/eye-crossed.svg";
import dotSvg from "../../icons/dotIcon.svg";
import Check from "../../icons/check2.svg";
import Decline from "../../icons/decline.svg";
import Layout from "../../components/Layout/Layout";
import TextField from "../../components/TextField/TextField";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { passwordCheckAvailability } from "./functions";

type Props = {
  onClickPasswordCreate?: (pass: string) => {};
  header?: { title?: string; desc?: string };
};

const useStyles = tss
  .withParams<{
    customTheme: any;
  }>()
  .create(({ customTheme }) => ({
    textInputField: {
      paddingBottom: 16,
    },
    buttonWrap: {
      display: "flex",
      justifyContent: "center",
    },
    loginButton: {
      width: "100%",
      marginTop: customTheme.custom.spacing.lg.value,
    },
    explanationText: {
      textAlign: "left",
      marginBottom: 8,
      color: customTheme.custom.color.text.secondary.value,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "21px",
      span: {
        fontWeight: 500,
      },
    },
    explanation: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      gap: customTheme.custom.spacing.xs.value,
    },
  }));

function PasswordCreate(props: Props) {
  const theme = useTheme();

  const { classes } = useStyles({
    customTheme: theme,
  });
  const { t } = useTranslation();
  const [passwordCheck, setPasswordCheck] = useState("");
  const [pass, setPass] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordCheck, setShowPasswordCheck] = useState(false);
  const [checkConditionFirst, setCheckConditionFirst] = useState(false);
  const [checkConditionSecond, setCheckConditionSecond] = useState(false);
  const [errPasswordCheck, setErrPasswordCheck] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (pass.length !== 0 && passwordCheckAvailability(pass)) {
      setCheckConditionSecond(true);
    } else {
      setCheckConditionSecond(false);
    }
    if (errPasswordCheck) {
      setErrPasswordCheck(false);
    }
  }, [pass, passwordCheck]);

  const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    if (
      NUMBER_CASE.test(event.target.value) &&
      event.target.value.length <= PASSWORD_LENGTH
    ) {
      if (event.target.value.length === PASSWORD_LENGTH) {
        setCheckConditionFirst(true);
      } else {
        setCheckConditionFirst(false);
      }
      setPass(event.target.value);
    }
  };

  const handleChangePasswordCheck = (event: ChangeEvent<HTMLInputElement>) => {
    if (
      NUMBER_CASE.test(event.target.value) &&
      event.target.value.length <= PASSWORD_LENGTH
    ) {
      setPasswordCheck(event.target.value);
    }
  };

  const handleButtonClick = async () => {
    if (pass !== passwordCheck) {
      setErrPasswordCheck(true);
    } else {
      props.onClickPasswordCreate && props.onClickPasswordCreate(pass);
    }
  };

  const handleHeaderBack = () => {
    navigate("/");
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordCheck = () =>
    setShowPasswordCheck((show) => !show);

  return (
    <Layout
      header={
        props.header
          ? props.header
          : {
              title: t("password.header.title"),
              desc: t("password.header.desc"),
            }
      }
      _handleBackAction={handleHeaderBack}
    >
      <Grid item xs={12}>
        <TextField
          error={errPasswordCheck}
          id="outlined-basic"
          header={t("password.input.title")}
          className={classes.textInputField}
          type={showPassword ? "text" : "password"}
          value={pass}
          onChange={handleChangePassword}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <img src={eyeSvg} alt="eye" />
                  ) : (
                    <img src={eyeCrossedSvg} alt="eye" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          error={errPasswordCheck}
          helperText={errPasswordCheck ? t("password.error") : ""}
          id="outlined-basic"
          header={t("password.input.title2")}
          className={classes.textInputField}
          type={showPasswordCheck ? "text" : "password"}
          value={passwordCheck}
          onChange={handleChangePasswordCheck}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPasswordCheck}
                  edge="end"
                >
                  {showPasswordCheck ? (
                    <img src={eyeSvg} alt="eye" />
                  ) : (
                    <img src={eyeCrossedSvg} alt="eye" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.explanation}>
          <img
            src={
              pass.length > 0 ? (checkConditionFirst ? Check : Decline) : dotSvg
            }
            alt="dot"
          ></img>
          <span className={classes.explanationText}>
            {t("password.explanation.password")}{" "}
            <span>{t("password.explanation.condition3")}</span>{" "}
            {t("password.explanation.justNumbers")}
          </span>
        </div>
        <div className={classes.explanation}>
          <img
            src={
              pass.length === 0
                ? dotSvg
                : checkConditionSecond
                ? Check
                : Decline
            }
            alt="dot"
          ></img>
          <span className={classes.explanationText}>
            {t("password.explanation.password")}{" "}
            <span>{t("password.explanation.condition1")}</span>{" "}
            {t("password.explanation.recursive")}{" "}
            <span>{t("password.explanation.condition2")}</span>{" "}
            {t("password.explanation.consecutive")}
          </span>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.buttonWrap}>
        <Button
          type="tertiary"
          variant="contained"
          text={t("password.passwordButtonText")}
          onClick={handleButtonClick}
          className={classes.loginButton}
        />
      </Grid>
    </Layout>
  );
}

export default PasswordCreate;
