import { useEffect, useState } from "react";
import {
  sendResetPinEmailOtp,
  verifyResetPinEmailOtp,
} from "../../services/msisdnOtpServices";
import PasswordCreate from "../Password/PasswordCreate";
import { resetPin } from "../../services/passwordServices";
import SecurityQuestions from "../SecurityQuestions/SecurityQuestions";
import {
  userSecurityQuestion,
  verifySecurityQuestion,
} from "../../services/securityQuestionsServices";
import Info from "../../components/Info/Info";
import StatusBadge from "../../icons/status-badge.svg";
import ContactUs from "../../icons/contactUs.svg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import OtpPages from "../Otp/OtpPages";
import { sendLoginOtp } from "../../services/loginServices";
function PasswordForgetProcess() {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [resultOfService, setResultOfService] = useState({
    otpId: "",
    email: "",
    msisdn: "",
  });
  const [resetPinToken, setResetPinToken] = useState("");
  const [question, setQuestion] = useState([{ id: "", answer: "" }]);
  const [forgetAnswer, setForgetAnswer] = useState(false);
  const profile = useSelector((state: any) => state.profile);

  useEffect(() => {
    const fetchQuestions = async () => {
      const result = await userSecurityQuestion().then((response) => {
        return response;
      });

      if (result && result.id) {
        let newArr: any = [];
        let newObj = { id: result.id, name: result.question };
        newArr.push(newObj);
        setQuestion(newArr);
      }
    };

    fetchQuestions();
  }, []);

  const handleClickSecurityQuestions = async (id: string, answer: string) => {
    const body = {
      id: question[0].id,
      answer,
    };

    const result = await verifySecurityQuestion(body).then((response) => {
      return response;
    });
    // Password Create
    if (result && result.resetPinToken) {
      setResetPinToken(result.resetPinToken);
      setStep(2);
    }
  };

  const handleClickPasswordCreate = async (pass: string) => {
    const body = {
      newPin: pass,
      resetPinToken: resetPinToken,
    };
    const result = await resetPin(body).then((result: any) => {
      return result;
    });
    result && result.status === 200 && setStep(4);
  };

  const handleClickForgetAnswer = async () => {
    if (profile?.email === null) {
      // akis 4 bize ulasin
      setStep(5);
    } else if (profile?.email) {
      const result = await sendResetPinEmailOtp().then((result: any) => {
        return result;
      });
      setForgetAnswer(true);
      if (result && result.otpId) {
        await setResultOfService(result);
        setStep(1);
      }
    }
  };

  const handleClickVerifyEmailOtp = async (otpValue: string) => {
    const body = {
      otpId: resultOfService.otpId,
      email: resultOfService.email,
      otpCode: otpValue,
    };
    const result = await verifyResetPinEmailOtp(body).then((result: any) => {
      return result;
    });
    if (result && result.resetPinToken) {
      setResetPinToken(result.resetPinToken);
      setStep(2);
    }
  };

  const handleSendEmailOtpAgain = async () => {
    const result = await sendResetPinEmailOtp().then((result: any) => {
      return result;
    });
    if (result && result.otpId) {
      setResultOfService(result);
    }
  };

  const handleClickInsertSecurity = async () => {
    // TO DO update security question answer
    // if (result && result.status === 200) setStep(4);
  };

  const handleButtonForgetEmail = () => {
    setStep(5);
  };

  const handleSendAgainOtp = async (type: string) => {
    const body = {
      username: resultOfService.msisdn,
    };

    const responseResult = await sendLoginOtp(body).then((response) => {
      return response;
    });
    if (responseResult) {
      setResultOfService(responseResult);
    }
  };

  switch (step) {
    case 0: // Security Question
      return (
        <SecurityQuestions
          onClickSecurityQuestions={handleClickSecurityQuestions}
          forgetPasswordStep
          defaultQuestion={question}
          defaultValue={question[0].id}
          onClickForgetAnswer={handleClickForgetAnswer}
        />
      );
    case 1: // Security Question
      return (
        <OtpPages
          type="emailOpt"
          header={{
            title: t("mailOtp.headerTitle"),
            dsc: t("mailOtp.headerDesc", {
              userEmail: resultOfService.email,
            }),
          }}
          resultOfService={resultOfService}
          handleSendAgainOtp={handleSendEmailOtpAgain}
          buttonTertiary={{
            buttonTertiaryTitle: t("mailOtp.buttonTertiaryTitle"),
            buttonTertiaryPress: async (otpValue: string) => {
              return await handleClickVerifyEmailOtp(otpValue);
            },
          }}
          buttonText={{
            buttonTextTitle: t("mailOtp.buttonTextTitle"),
            buttonTextPress: async () => {
              handleButtonForgetEmail();
            },
          }}
        />
      );
    case 2: // Password Create
      return (
        <PasswordCreate
          header={{
            title: t("password.forget.newPasswordTitle"),
          }}
          onClickPasswordCreate={handleClickPasswordCreate}
        />
      );
    case 3: // insert security question for existing user
      return (
        <SecurityQuestions
          onClickSecurityQuestions={handleClickInsertSecurity}
        />
      );

    case 4: // Success
      return (
        <Info
          icon={StatusBadge}
          title={t("password.forget.newPasswordSuccessTitle")}
          desc={t("password.forget.newPasswordSuccessDesc")}
          buttonText={t("password.forget.newPasswordSuccessButtonText")}
          handleContinue={() => {
            window.location.reload();
          }}
        />
      );
    case 5: // Contact Us
      return (
        <Info
          icon={ContactUs}
          title={t("password.forget.contactUsTitle")}
          desc={t("password.forget.contactUsDesc")}
          buttonText={t("password.forget.contactUsButtonText")}
        />
      );
    default:
      break;
  }

  return <></>;
}

export default PasswordForgetProcess;
