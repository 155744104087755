import { customApiRequest } from "../util/httpUtils";

const lang = localStorage.getItem("language");

export async function getUserProfile() {
  // Get User Profile
  const accessToken: string | null = localStorage.getItem("access_token");
  const options = {
    method: "GET",
    url: "https://tabiat.ekomobil.com.tr/dbys/v1/users/profile",
    headers: {
      Authorization: accessToken,
      "Content-Type": "application/json",
      "Accept-Language": lang,
    },
  };
  const result = await customApiRequest(options);
  return result;
}

export async function uploadProfilePicture(file: any) {
  const accessToken: string | null = localStorage.getItem("access_token");
  const formData = new FormData();
  formData.append("file", file);
  const options = {
    method: "POST",
    url: "https://tabiat.ekomobil.com.tr/dbys/v1/users/profile-picture",
    headers: {
      Authorization: accessToken,
      "Content-Type": "multipart/form-data",
      "Accept-Language": lang,
    },
    data: formData,
  };

  const result = await customApiRequest(options);
  return result;
}

export async function deleteProfilePicture() {
  // Get User Profile
  const accessToken: string | null = localStorage.getItem("access_token");
  const options = {
    method: "DELETE",
    url: "https://tabiat.ekomobil.com.tr/dbys/v1/users/profile-picture",
    headers: {
      Authorization: accessToken,
      "Accept-Language": lang,
    },
  };

  const result = await customApiRequest(options);
  return result;
}
