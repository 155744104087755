import { Box, Grid } from "@mui/material";
import { tss } from "tss-react/mui";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useTheme } from "@emotion/react";
import HomeBack from "../../icons/homeBack.svg";

type Props = {
  children?: any;
  hideHeaderBack?: boolean;
  _handleBackAction?: any;
  header?: { title?: string; desc?: string; icon?: any };
  propPadding?: string;
  isLogin?: boolean;
  isBack?: boolean;
  bg?: boolean;
  layoutPadding?: number;
};

const useStyles = tss
  .withParams<{
    customTheme: any;
    propPadding?: string;
    isBack?: boolean;
    bg?: boolean;
    layoutPadding?: number;
  }>()
  .create(({ customTheme, propPadding, isBack, bg, layoutPadding }) => ({
    title: {
      color: customTheme.custom.color.text.primary.value,
      fontSize: 24,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "130%",
      paddingBottom: customTheme.custom.spacing.xs.value,
    },
    labelText: {
      color: customTheme.custom.color.text.secondary.value,
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "150%",
      paddingBottom: customTheme.custom.spacing.lg.value,
    },
    box: {
      flexGrow: 1,
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
    },
    boxMain: {
      flex: 1,
      minHeight: "calc(100vh - 104px)",
    },
    root: {
      paddingTop: isBack ? 0 : customTheme.custom.spacing["2xl"].value,
      paddingLeft: layoutPadding ? "13.3565%" : "20.8909%", // layoutPadding ? layoutPadding : 244
      paddingRight: layoutPadding ? "13.3565%" : "20.8909%", // layoutPadding ? layoutPadding : 244
    },
    contentContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: propPadding
        ? propPadding
        : `${customTheme.custom.spacing["2xl"].value} ${customTheme.custom.spacing.xl.value}`,
      borderRadius: customTheme.custom.radius.xl.value,
      background: bg ? "#f5f5f5" : customTheme.custom.color.surface.white.value,
      boxShadow: bg ? "none" : "0px 4px 8px 0px rgba(0, 0, 0, 0.12)",
      position: "relative",
    },
    content: {
      maxWidth: !propPadding ? 400 : "none",
    },
    headerIcon: {
      paddingBottom: customTheme.custom.spacing.md.value,
    },
    homeBack: {
      padding: "20px 16px",
      display: "flex",
    },
    pointer: {
      cursor: "pointer",
      position: "absolute",
      top: 8,
      left: 8,
    },
    backButtonFrame: {
      width: 40,
      height: 40,
      background: customTheme.custom.color.surface.variant.value,
      borderRadius: 100,
      position: "relative",
    },
  }));

function Layout(props: Props) {
  const theme = useTheme();

  const { classes } = useStyles({
    customTheme: theme,
    propPadding: props.propPadding,
    isBack: props.isBack,
    bg: props.bg,
    layoutPadding: props.layoutPadding,
  });

  return (
    <>
      <Box className={classes.boxMain}>
        {!props.isLogin && (
          <Header
            hideHeaderBack={props.hideHeaderBack}
            _handleBackAction={
              props._handleBackAction
                ? props._handleBackAction
                : () => window.location.reload()
            }
          ></Header>
        )}
        {props.isBack && (
          <div className={classes.homeBack}>
            <div className={classes.backButtonFrame}>
              <img
                className={classes.pointer}
                src={HomeBack}
                alt="home-back"
                onClick={
                  props._handleBackAction
                    ? props._handleBackAction
                    : () => window.location.reload()
                }
              />
            </div>
          </div>
        )}
        <Box className={classes.box}>
          <Grid container className={classes.root}>
            <Grid container className={classes.contentContainer}>
              <Grid container className={classes.content}>
                {props.header && (
                  <>
                    {props.header.icon && (
                      <Grid item xs={12}>
                        <img
                          className={classes.headerIcon}
                          src={props.header.icon}
                          alt="header-icon"
                        />
                      </Grid>
                    )}
                    {props.header.title && (
                      <Grid item xs={12}>
                        <div className={classes.title}>
                          {props.header.title}
                        </div>
                      </Grid>
                    )}
                    {props.header.desc && (
                      <Grid item xs={12}>
                        <div className={classes.labelText}>
                          {props.header.desc}
                        </div>
                      </Grid>
                    )}
                  </>
                )}
                {props.children && props.children}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {!props.isLogin && <Footer />}
    </>
  );
}

export default Layout;
