import { Grid } from "@mui/material";
import { tss } from "tss-react/mui";
import FooterLogo from "../../icons/footerLogo.svg";

const useStyles = tss.create(({ theme }) => ({
  container: {
    width: "100%",
    position: "relative",
    left: 0,
    bottom: 0,
  },
  containerIcon: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    paddingTop: 40,
    paddingBottom: 10,
  },
}));

function Footer(props: any) {
  const { classes } = useStyles();

  return (
    <Grid className={classes.container}>
      <Grid className={classes.containerIcon}>
        <img src={FooterLogo} alt="Footer Icon" />
      </Grid>
    </Grid>
  );
}

export default Footer;
