import { customApiRequest } from "../util/httpUtils";

const lang = localStorage.getItem("language");

export async function sendLoginOtp(body: object) {
  const options = {
    method: "POST",
    url: "https://tabiat.ekomobil.com.tr/dbys/v1/auth/send-login-otp",
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": lang,
    },
    data: body,
  };
  const result = await customApiRequest(options);
  return result;
}

export async function verifyPin(body: object) {
  const accessToken: string | null = localStorage.getItem("access_token");
  const options = {
    method: "POST",
    url: "https://tabiat.ekomobil.com.tr/dbys/v1/auth/verify-pin",
    headers: {
      Authorization: accessToken,
      "Content-Type": "application/json",
      "Accept-Language": lang,
    },
    data: body,
  };
  const result = await customApiRequest(options);

  return result;
}

export async function refreshToken() {
  const refreshToken: string | null = localStorage.getItem("refresh_token");
  const options = {
    method: "POST",
    url: "https://tabiat.ekomobil.com.tr/dbys/v1/auth/refresh-token",
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": lang,
    },
    data: { refreshToken: refreshToken },
  };
  const result = await customApiRequest(options);
  if (result.refreshToken && result.accessToken) {
    localStorage.setItem("refresh_token", result.refreshToken);
    localStorage.setItem("access_token", result.accessToken);
  }

  return result;
}

export async function logout() {
  const accessToken: string | null = localStorage.getItem("access_token");
  const options = {
    method: "POST",
    url: "https://tabiat.ekomobil.com.tr/dbys/v1/auth/logout",
    headers: {
      Authorization: accessToken,
      "Content-Type": "application/json",
      "Accept-Language": lang,
    },
  };
  const result = await customApiRequest(options);
  return result;
}
